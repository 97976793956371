import React, { useState } from "react";

import brandLogo from "../../assets/images/logoWOname.png";
import ActiveComp1 from "./ActiveComp1";
import ActiveComp2 from "./ActiveComp2";
import ActiveComp3 from "./ActiveComp3";

const BuildAgent = () => {
  const [activeComp, setActiveComp] = useState(1);
  const [tagsLoading, setTagsLoading] = useState(true)
  const [bestActionLoading, setBestActionLoading] = useState(false)

  return (
    <div className="flex gap-2">
      <div className="flex flex-col w-[25%] gap-5">
        {/* <div className="flex items-center gap-2">
          <img src={brandLogo} alt="brandLogo" width={40} />
          <div className="text-justify border border-[#609DA1] text-[#609DA1] rounded-md p-2 text-[0.75rem]">
            Here are the instructions that I have given to your Workflow Agent
          </div>
        </div>
        <div className="text-justify border border-[#609DA1] text-[#609DA1] rounded-md p-2 text-[0.75rem] ml-12">
          Please hit save and run if the parameters are correct
        </div> */}
        {activeComp !== 1 && (
          <div className="flex items-center gap-2">
            <img src={brandLogo} alt="brandLogo" width={40} />
            <div className="text-start border border-[#609DA1] text-[#609DA1] rounded-md p-2 text-[0.75rem]">
              Looks like the agent was built successfully. Please hit test if you are satisfied with the build
            </div>
          </div>
          // <div className="text-justify border border-[#609DA1] text-[#609DA1] rounded-md p-2 text-[0.75rem]">
          //   Looks like the agent was built successfully. Please hit test if you are satisfied with the build
          // </div>
        )}
        {activeComp === 3 && !tagsLoading && (
          <div className="text-start border border-[#609DA1] text-[#609DA1] rounded-md p-2 text-[0.75rem] ml-12">
            Adding Tags Tested Successfully
          </div>
        )}
        {activeComp === 3 && !tagsLoading && !bestActionLoading && (
          <div className="text-start border border-[#609DA1] text-[#609DA1] rounded-md p-2 text-[0.75rem] ml-12">
            Adding Next Best Action Tested Successfully
          </div>
        )}
      </div>
      <div className="flex flex-col w-full border border-4 h-[90vh]">
        <div className="w-full text-center bg-[#609DA1] text-white">
          Worflow Agent
        </div>
        <div className="flex justify-around items-center border">
          <div className="relative cursor-not-allowed text-gray-400">
            <span className="absolute h-full border-r border-black right-[-125%]"></span>
            Agent Library
          </div>
          <div className="relative cursor-pointer text-[#609DA1] shadow-xl">
            <span className="absolute h-full border-r border-black right-[-125%]"></span>
            Agent Details
          </div>
          <div className="relative cursor-not-allowed text-gray-400">
            <span className="absolute h-full border-r border-black right-[-175%]"></span>
            Reporting
          </div>
          <div className="cursor-not-allowed text-gray-400">Log</div>
        </div>

        {activeComp === 1 ? (
          <ActiveComp1 setActiveComp={setActiveComp} />
        ) : activeComp === 2 ? (
          <ActiveComp2 setActiveComp={setActiveComp} />
        ) : (
          <ActiveComp3 tagsLoading={tagsLoading} setTagsLoading={setTagsLoading} bestActionLoading={bestActionLoading} setBestActionLoading={setBestActionLoading} />
        )}
      </div>
    </div>
  );
};

export default BuildAgent;
