import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import TypingEffect from "../TypingEffect/TypingEffect";

const AudienceTypingEffectUtil = ({ chatArray, isNumberedList, renderLink, setCompleted, chatContentRef, setLoading, fieldArray, setFieldArray, setAttachedFile }) => {
    // console.log(chatArray)
    const lastChat = chatArray?.filter((chat, index) => index === chatArray?.length - 1)
    const arrToMap = lastChat[0]?.answer;
    const newArray = arrToMap?.map((ans, ansIndex) => (
      <div key={`ans_${ansIndex}`} style={{ paddingLeft: isNumberedList(ans) ? "70px" : "2.5rem" }} className={` ${isNumberedList(ans) && 'my-2'} text-justify text-base`}>
        {isNumberedList(ans) ? (
          <div className={`flex gap-${Number(ans?.split(" ")[0]?.split(".")[0]) < 10 ? '5' : '3'}`}>
            <div className="text-end">
              {ans?.split(" ")[0]?.trim()}
            </div>
            <div>
              {renderLink(ans?.split(" ").slice(1).join(" ").trim())}
            </div>
          </div>
        ) : (
          <>{renderLink(ans?.trim())}</>
        )}
      </div>
    ))
    const htmlString = ReactDOMServer.renderToString(newArray);
  
    // console.log(htmlString);
    let intervalId;
    const onTypingComplete = () => {
    //   console.log("done");
    // Handling Case for Field Mapping in File Attach case Starts ------------------------->
    if (lastChat[0]?.answer[0] === "I am analysing the file you have uploaded:") {
      const answerLength = lastChat[0].answer?.length
      for (let i = 3; i < answerLength; i++) {
        const line = lastChat[0].answer[i]
        const pattern = /"([^"]*?)"/g
        const match = line.match(pattern)
        if (match) {
          const cleanedMatches = match.map(item => item.replace(/^"|"$/g, ''))
          fieldArray = fieldArray.concat(cleanedMatches)
        }
      }
      // console.log(fieldArray)
      setFieldArray(fieldArray)
    }
    // Handling Case for Field Mapping in File Attach case Ends ------------------------->
      setCompleted(true);
      setLoading(false);
      if (chatArray[0]?.question?.toLowerCase()?.trim()?.includes("data ops: build a data product, clean, enrich, dedupe list, encrypt pii info")) {
        console.log("gerg")
        setAttachedFile(null)
      }
      clearInterval(intervalId)
    }
    useEffect(() => {
      if (chatArray?.length > 1) {
        intervalId = setInterval(() => {
          chatContentRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }, 500);
      }
      return () => clearInterval(intervalId);
    }, [])
    if (lastChat?.length > 0) {
      return (
        <>
        <TypingEffect 
          html={htmlString}
          speed={0.5}
          onTypingComplete={onTypingComplete}
        />
        </>
      );
    }
  };

export default AudienceTypingEffectUtil;