import React, { useState, useRef, useEffect } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";

// import ExtraInfoTable from "./ExtraInfoTable.js";

import salesforceImg from "../../assets/images/salesforceImg.png";
import slackLogo from "../../assets/images/slackLogo.png";
import gmailLogo from "../../assets/images/gmail.png";
import hubspotLogo from "../../assets/images/hubspotLogo.png";

import { schneiderFetch } from "../../services/SchneiderServices.js";

const ExtraInfo = () => {
  const [delegateTask, setDelegateTask] = useState(false);

  const customerRef = useRef(null)
  const taskRef = useRef(null)

  let timeoutId;
  useEffect(() => {
    if (customerRef.current) {
      timeoutId = setTimeout(() => {
        customerRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }, 500)
    }

    return () => clearTimeout(timeoutId)
  }, [])

  let taskTimeout
  useEffect(() => {
    if (delegateTask && taskRef.current) {
      taskTimeout = setTimeout(() => {
        taskRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
      }, 10)
    }

    return () => clearTimeout(taskTimeout)
  }, [delegateTask])

  const handleMessageSend = async () => {
    const fetchingData = {
        channel: "D06SFUAF6KZ",
        text: "davidmessage"
    }
    const response = await schneiderFetch(fetchingData)
    console.log(response)
  }

  const items = [
    {
      key: "1",
      label: (
        <div className="flex ml-0.5 gap-3 justify-start items-center" onClick={handleMessageSend}>
          <img src={slackLogo} alt="slack" className="w-[1rem]" />
          <div>Message</div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="flex gap-3 justify-start items-center">
          <img src={gmailLogo} alt="gmail" className="w-[1.2rem]" />
          <div>Email</div>
        </div>
      ),
      disabled: true,
    },
    {
      key: "3",
      label: (
        <div className="flex gap-3 justify-start items-center">
          <img src={salesforceImg} alt="salesforce" className="w-[1.2rem]" />
          <div>Task</div>
        </div>
      ),
      disabled: true,
    },
    {
      key: "4",
      label: (
        <div className="flex gap-3 justify-start items-center">
          <img src={hubspotLogo} alt="hubspot" className="w-[1.2rem]" />
          <div>Task</div>
        </div>
      ),
      disabled: true,
    },
  ];
  return (
    <div className="flex flex-col text-[1.07rem] leading-[1.75rem] pl-10 mt-[-40px]">
      <div>Here is a list of customers that are at risk of churn</div>
      {/* <ExtraInfoTable /> */}
      <div className="flex flex-col gap-5">
        <div>
          From my analysis it looks like there are 3 core reasons why these
          accounts pose a churn risk:
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex gap-5 pl-2">
            <div>1.</div>
            <div className="ml-1">Signal: Champion Left</div>
          </div>
          <div className="flex gap-5 pl-2">
            <div>2.</div>
            <div>Signal: Regulatory Indicators</div>
          </div>
          <div className="flex gap-5 pl-2">
            <div>3.</div>
            <div>Signal: M&A News</div>
          </div>
        </div>
        <div>Here are my recommendations for next best actions:</div>
        <div className="flex justify-start gap-5 flex-wrap items-center w-[95%]">
          <div
            className={`py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-pointer leading-[1rem] w-fit rounded-md hover:bg-[#609DA1] hover:text-white ${
              delegateTask && "bg-[#609DA1] text-white"
            }`}
            onClick={() => setDelegateTask(true)}
            ref={customerRef}
          >
            Delegate task with suggestions
          </div>
          <div className="py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-not-allowed leading-[1rem] w-fit rounded-md">
            Create Churn Segmentation Tags & Slack Account Owners
          </div>
          <div className="py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-not-allowed leading-[1rem] w-fit rounded-md">
            Create a Slack alert when a champion leaves a customer company
          </div>
          <div className="py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-not-allowed leading-[1rem] w-fit rounded-md">
            Launch an agent automatically track and tag at risk customer
            accounts and slack account owner
          </div>
        </div>
        {delegateTask && (
          <>
            <div className="mt-1">
              Who would you like me to delegate the task to?
            </div>
            <div className="flex gap-2 text-[14px]">
              <Space className="px-2 border border-[#609DA1] text-[#609DA1] rounded-md cursor-not-allowed">
                Abhi Yadav
                <DownOutlined />
              </Space>
              <Dropdown menu={{ items }}>
                <Space ref={taskRef} className="px-2 border border-[#609DA1] text-[#609DA1] rounded-md cursor-pointer">
                  David Slavich
                  <DownOutlined />
                </Space>
              </Dropdown>
              <Space className="px-2 border border-[#609DA1] text-[#609DA1] rounded-md cursor-not-allowed">
                BOB MOPS
                <DownOutlined />
              </Space>
              <Space className="px-2 border border-[#609DA1] text-[#609DA1] rounded-md cursor-not-allowed">
                Marketing Users
                <DownOutlined />
              </Space>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ExtraInfo;
