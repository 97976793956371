import { shareCSVData } from "../services/freemiumServices";
import { message } from "antd";

export const shareDataHelper = async (fileInfo, loading, setLoading, messageFileName) => {
   //  console.log(loading);
    setLoading(true);
    let response = await shareCSVData(fileInfo);
   //  console.log(response);
    setLoading(false);
    if (response?.data?.status === "success") {
         message.success(`Data from ${messageFileName} Uploaded Successfully`);
         window.gtag('event', 'SaveSuccess', {
            event_category: 'CSVupload',
            event_label: 'CSV File Uploaded',
            value: [response.data?.ids],
          });
    } else {
       message.error("Upload Failed");
    }
}