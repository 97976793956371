import React, { useState, useEffect, useRef } from "react";

import { PlusCircleOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Switch, Input } from "antd";
import loadingGif from '../../assets/images/powerUserLoading.gif';

import "./SDLater.css";
import SDChat from "../SDChat/SDChat";

const { TextArea } = Input;
const SDLater = ({ question, answer, setQuestion, loading, setLoading }) => {
  const [rows, setRows] = useState(1);  
  const containerRef = useRef(null);
  const [inputFilled, setInputFilled] = useState(false);
  const [chatArray, setChatArray] = useState([]);
  const handleInputChange = (e) => {
    if (e.target.value.trim() === "") {
      setInputFilled(false);
    } else {
      setInputFilled(true);
    }
    const lines = e.target.value.split("\n").length;
    const newRows = Math.min(lines, 5);
    setRows(newRows || 1);
    setQuestion(e.target.value);
  };
  useEffect(() => {
    if (!loading && containerRef.current) {
      // Scroll to the bottom of the container
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [loading]);
  useEffect(() => {
    if(question == "Which customers are at a high risk of churn?"){
      setChatArray([
        {
          question: question,
          answer: ["Analyzing current customers that are at risk of churn"],
          isGraph: false,
          suggestions: [],
          isSuggestions: false
        }
      ])
    }
    else if(question == "Reports & Analytics: Create reports, forecasting, campaign analytics"){
      setChatArray([
        {
          question: question,
          answer: ["It looks like you are looking for insights, here are few suggestions for you"],
          isGraph: false,
          suggestions: ["Why is my Revenue down this week?", "How did our latest campaign perform last week?"],
          isSuggestions: true
        },
      ]);
    }
    else if(question.includes("You've uploaded")){
      setChatArray([
        {
          question: question,
          answer: "I am analysing the file you have uploaded:\n1. File has 5 columns\n2. company_name column represent name of companies and has no missing values\n3. email column represents emails of individual, it is a PII filed and has 20% missing values\n4. website column represents URL's with 10% missing values\n5. contact_name represents name of individuals with no missing values\n6. company_country represents country with no missing values.\n7. There are 12% duplicate rows in the file".split('\n'),
          isGraph: false,
          suggestions: ["Clean and deduplicate file?", "Clean, deduplicate and check for net new records?", "Ask questions about the file?"],
          isSuggestions: true
        },
      ]);
    }
  }, []);
  useEffect(() => {
    if (chatArray?.length === 1) {
      setQuestion("");
    }
  }, [chatArray]);
  const handleSend = async () => {
    if (inputFilled) {
        // setLoading(true);
        if(question.toLowerCase().trim().includes("which customers are at a high risk of churn?")){
          setChatArray((prevChatArray) => [
            ...prevChatArray,
            {
              question: question,
              answer: ["Analyzing current customers that are at risk of churn"],
              isGraph: false,
              suggestions: [],
              isSuggestions: false
            },
          ]);
        }
        else {
          setChatArray((prevChatArray) => [
            ...prevChatArray,
            {
              question: question,
              answer: ["Cannot Process That"],
            },
          ]);
        }
      }
        setQuestion("");
        setInputFilled(false);
  };

  return (
    <div className="h-[80%]">
      <div ref={containerRef} className="pl-10 w-full h-[90%]" style={{overflowY: "scroll", overflowX: "hidden"}}>
        <SDChat setQuestion={setQuestion} setInputFilled={setInputFilled} handleSend={handleSend} chatArray={chatArray} setLoading={setLoading} />
      </div>
      {loading ? (
        <div className="flex justify-center" style={{alignItems: "center"}} >
        <img
				src={loadingGif}
				alt="loadingGif"
				className="h-[150px]"
			  />
        </div>
      ) : (
        <div className="w-[50%] absolute bottom-[5%] left-[25%]">
          <div className="border border-[#4B9DA1] bg-[#4B9DA1] rounded-full p-1">
            <div
              className={`border border-[#609DA1] shadow-xl rounded-full px-2 py-1 flex justify-between items-center bg-white`}
            >
              <div className="flex gap-5 justify-center items-center w-[80%]">
                <PlusCircleOutlined style={{ fontSize: "20px", cursor: "not-allowed" }}/>
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  value={question}
                  rows={rows}
                  onChange={handleInputChange}
                  placeholder="Ask follow-up..."
                  className={`text-xl w-full laterTextArea blackText`}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                />
              </div>
              <div className="flex justify-center items-center gap-5 mt-0.5">
                <div
                  className={`flex justify-center items-center gap-2 border-2 rounded-2xl p-2 ${
                    inputFilled
                      ? "bg-[#609DA1] cursor-pointer"
                      : "cursor-not-allowed"
                  }`}
                  onClick={() => handleSend()}
                >
                  <ArrowUpOutlined
                    style={{ color: inputFilled ? "white" : "black" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SDLater;
