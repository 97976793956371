import { useEffect, useState } from "react";

import { getSiteInfo } from "../../services/gtmJSServices";

import { Select, Table, Typography } from "antd";
import gtmCopilotName from "../../assets/images/gtmCopilotname.svg";

const { Text } = Typography;
const columns = [
  {
    title: "Site Link",
    key: "site_name",
    dataIndex: "site_name",
  },
  {
    title: "User Name",
    key: "user_name",
    dataIndex: "user_name",
  },
  {
    title: "User Email",
    key: "user_email",
    dataIndex: "user_email",
  },
  {
    title: "Country",
    key: "country",
    dataIndex: "country",
  },
  {
    title: "Region",
    key: "region",
    dataIndex: "region",
  },
  {
    title: "Client IP",
    key: "ip",
    dataIndex: "ip",
  },
  {
    title: "Timestamp",
    key: "timestamp",
    dataIndex: "timestamp",
  },
];
const formatDate = (dateString) => {
  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

const GTMWebAdmin = () => {
  const [data, setData] = useState(null);
  const [uniqueSites, setUniqueSites] = useState([]);
  const [mainData, setMainData] = useState([]);

  const uSites = [];
  const fetchData = async () => {
    setData([]);
    const response = await getSiteInfo({});
    // console.log(response.data.siteInfo);
    const data = response.data.siteInfo;
    setMainData(data);
    data.forEach((item, index) => {
      let site = item?.site_name || "Not Available";
      if (item.site_href && item.site_href.trim() !== "undefined" && item.site_href.trim() !== "") {
        site = item.site_href;
        if (site?.includes("verify-user")) {
          site = "https://ada.gtmcopilot.com/verify-user/.../..."
        }
      }
      let country_name = item?.country_name || "Not Available";
      if (country_name.trim() === "") {
        country_name = "Not Available"
      }
      let region_name = item?.region_name || "Not Available";
      if (region_name.trim() === "") {
        region_name = "Not Available"
      }
      const newRow = {
        key: `${item?.id}_${index}`,
        site_id: (
          <>
            <Text level={5}>{item?.site_id || "Not Available"}</Text>
          </>
        ),
        site_name: (
          <>
            <Text level={5}>{site}</Text>
          </>
        ),
        user_name: (
          <>
            <Text level={5}>{item?.user_name?.replaceAll("|", " ") || "Not Available"}</Text>
          </>
        ),
        user_email: (
          <>
            <Text level={5}>{item?.user_email || "Not Available"}</Text>
          </>
        ),
        region: (
          <>
            <Text level={5}>{region_name}</Text>
          </>
        ),
        country: (
          <>
            <Text level={5}>{country_name}</Text>
          </>
        ),
        ip: (
          <>
            <Text level={5}>{item?.ip || "Not Available"}</Text>
          </>
        ),
        timestamp: (
          <>
            <Text level={5}>
              {item?.timestamp ? formatDate(item?.timestamp) : "Not Available"}
            </Text>
          </>
        ),
      };
      setData((prevData) => [...prevData, newRow]);
      const valueExists = uSites.some((obj) => obj.value === item.site_id);
      if (!valueExists) {
        uSites.push({ label: item.site_name, value: item.site_id });
      }
      setUniqueSites(uSites);
    });
    setUniqueSites((prevSites) => [
      { value: "00", label: "All Sites" },
      ...prevSites,
    ]);
    // console.log(uniqueSites);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectChange = (siteID) => {
    // console.log(e);
    setData([]);
    if (siteID === "00") {
      mainData.forEach((item, index) => {
        let site = item?.site_name || "Not Available";
        if (item.site_href && item.site_href.trim() !== "undefined" && item.site_href.trim() !== "") {
          site = item.site_href;
        }
        let country_name = item?.country_name || "Not Available";
        if (country_name.trim() === "") {
          country_name = "Not Available"
        }
        let region_name = item?.region_name || "Not Available";
        if (region_name.trim() === "") {
          region_name = "Not Available"
        }
        const newRow = {
          key: `${item?.id}_${index}`,
          site_id: (
            <>
              <Text level={5}>{item?.site_id || "Not Available"}</Text>
            </>
          ),
          site_name: (
            <>
              <Text level={5}>{site}</Text>
            </>
          ),
          user_name: (
            <>
              <Text level={5}>{item?.user_name?.replaceAll("|", " ") || "Not Available"}</Text>
            </>
          ),
          user_email: (
            <>
              <Text level={5}>{item?.user_email || "Not Available"}</Text>
            </>
          ),
          region: (
            <>
              <Text level={5}>{region_name}</Text>
            </>
          ),
          country: (
            <>
              <Text level={5}>{country_name}</Text>
            </>
          ),
          ip: (
            <>
              <Text level={5}>{item?.ip || "Not Available"}</Text>
            </>
          ),
          timestamp: (
            <>
              <Text level={5}>
                {item?.timestamp ? formatDate(item?.timestamp) : "Not Available"}
              </Text>
            </>
          ),
        };
        setData((prevData) => [...prevData, newRow]);
      });
    } else {
      mainData.forEach((item, index) => {
        if (item.site_id === siteID) {
          let site = item?.site_name || "Not Available";
          if (item.site_href && item.site_href.trim() !== "undefined" && item.site_href.trim() !== "") {
            site = item.site_href;
          }
          let country_name = item?.country_name || "Not Available";
          if (country_name.trim() === "") {
            country_name = "Not Available"
          }
          let region_name = item?.region_name || "Not Available";
          if (region_name.trim() === "") {
            region_name = "Not Available"
          }
          const newRow = {
            key: `${item?.id}_${index}`,
            site_name: (
              <>
                <Text level={5}>{site}</Text>
              </>
            ),
            user_name: (
              <>
                <Text level={5}>{item?.user_name?.replaceAll("|", " ") || "Not Available"}</Text>
              </>
            ),
            user_email: (
              <>
                <Text level={5}>{item?.user_email || "Not Available"}</Text>
              </>
            ),
            region: (
              <>
                <Text level={5}>{region_name}</Text>
              </>
            ),
            country: (
              <>
                <Text level={5}>{country_name}</Text>
              </>
            ),
            ip: (
              <>
                <Text level={5}>{item?.ip || "Not Available"}</Text>
              </>
            ),
            timestamp: (
              <>
                <Text level={5}>
                  {item?.timestamp
                    ? formatDate(item?.timestamp)
                    : "Not Available"}
                </Text>
              </>
            ),
          };
          setData((prevData) => [...prevData, newRow]);
        }
      });
    }
  };

  return (
    <div className="h-screen w-full py-5 px-12 relative">
      <div className="absolute top-5 left-8">
        <img src={gtmCopilotName} alt="gtm-logo" width={150} />
      </div>
      <Select
        className="absolute right-10 top-11 w-[15%]"
        options={uniqueSites}
        defaultValue={{
          value: "00",
          label: "All Sites",
        }}
        onChange={handleSelectChange}
      />
      <div className="flex justify-center items-center text-4xl text-black my-5">
        GTM WEB ADMIN
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
        className="px-8"
      />
    </div>
  );
};

export default GTMWebAdmin;
