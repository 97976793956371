export const shapeOptions = [
    { label: "Shape 1", value: 1 },
    { label: "Shape 2", value: 2 },
    { label: "Shape 3", value: 3 },
    { label: "Shape 4", value: 4 },
    { label: "Shape 5", value: 5 },
    { label: "Shape 6", value: 6 },
    { label: "Shape 7", value: 7 },
    { label: "Shape 8", value: 8 },
    { label: "Shape 9", value: 9 },
    { label: "Shape 10", value: 10 },
    { label: "Shape 11", value: 11 },
    { label: "Shape 12", value: 12 },
    { label: "Shape 13", value: 13 },
    { label: "Shape 14", value: 14 },
    { label: "Shape 15", value: 15 },
    { label: "Shape 16", value: 16 },
    { label: "Shape 17", value: 17 },
    { label: "Shape 18", value: 18 },
    { label: "Shape 19", value: 19 },
    { label: "Shape 20", value: 20 },
    { label: "Shape 21", value: 21 },
    { label: "Shape 22", value: 22 },
    { label: "Shape 23", value: 23 },
    { label: "Shape 24", value: 24 },
    { label: "Shape 25", value: 25 },
    { label: "Shape 26", value: 26 },
    { label: "Shape 27", value: 27 },
    { label: "Shape 28", value: 28 },
    { label: "Shape 29", value: 29 },
    { label: "Shape 30", value: 30 },
    { label: "Shape 31", value: 31 },
    { label: "Shape 32", value: 32 },
    { label: "Shape 33", value: 33 },
    { label: "Shape 34", value: 34 },
    { label: "Shape 35", value: 35 },
    { label: "Shape 36", value: 36 },
    { label: "Shape 37", value: 37 },
];   

export const colorOptions = [
    // { label: "Color 1", value: 1 },
    { label: "Color 2", value: 2 },
    { label: "Color 3", value: 3 },
    { label: "Color 4", value: 4 },
    { label: "Color 5", value: 5 },
    { label: "Color 6", value: 6 },
    { label: "Color 7", value: 7 },
    { label: "Color 8", value: 8 },
    { label: "Color 9", value: 9 },
    { label: "Color 10", value: 10 },
    { label: "Color 11", value: 11 },
    { label: "Color 12", value: 12 },
    { label: "Color 13", value: 13 },
    { label: "Color 14", value: 14 },
    { label: "Color 15", value: 15 },
    { label: "Color 16", value: 16 },
    { label: "Color 17", value: 17 },
    { label: "Color 18", value: 18 },
    { label: "Color 19", value: 19 },
    { label: "Color 20", value: 20 },
    { label: "Color 21", value: 21 },
    { label: "Color 22", value: 22 },
    { label: "Color 23", value: 23 },
]; 

export const clarityOptions = [
    { label: "Clarity 1", value: 1 },
    { label: "Clarity 2", value: 2 },
    { label: "Clarity 3", value: 3 },
    { label: "Clarity 4", value: 4 },
    { label: "Clarity 5", value: 5 },
    { label: "Clarity 6", value: 6 },
    { label: "Clarity 7", value: 7 },
    { label: "Clarity 8", value: 8 },
    { label: "Clarity 9", value: 9 },
    { label: "Clarity 10", value: 10 },
    { label: "Clarity 11", value: 11 },
    { label: "Clarity 12", value: 12 },
]; 

export const cutOptions = [
    { label: "Cut 1", value: 1 },
    { label: "Cut 2", value: 2 },
    { label: "Cut 3", value: 3 },
    { label: "Cut 4", value: 4 },
    { label: "Cut 5", value: 5 },
    { label: "Cut 6", value: 6 },
    { label: "Cut 7", value: 7 },
]; 

export const polishOptions = [
    { label: "Polish 1", value: 1 },
    { label: "Polish 2", value: 2 },
    { label: "Polish 3", value: 3 },
    { label: "Polish 4", value: 4 },
    { label: "Polish 5", value: 5 },
    { label: "Polish 6", value: 6 },
    { label: "Polish 7", value: 7 },
    { label: "Polish 8", value: 8 },
    { label: "Polish 9", value: 9 },
]; 

export const symmetryOptions = [
    { label: "Symmetry 1", value: 1 },
    { label: "Symmetry 2", value: 2 },
    { label: "Symmetry 3", value: 3 },
    { label: "Symmetry 4", value: 4 },
    { label: "Symmetry 5", value: 5 },
    { label: "Symmetry 6", value: 6 },
    { label: "Symmetry 7", value: 7 },
    { label: "Symmetry 8", value: 8 },
    { label: "Symmetry 9", value: 9 },
]; 

export const milkyOptions = [
    { label: "Milky 1", value: 1 },
    { label: "Milky 2", value: 2 },
    { label: "Milky 3", value: 3 },
    { label: "Milky 4", value: 4 },
]; 

export const fluorescenceOptions = [
    { label: "F-Intensity 1", value: 1 },
    { label: "F-Intensity 2", value: 2 },
    { label: "F-Intensity 3", value: 3 },
    { label: "F-Intensity 4", value: 4 },
    { label: "F-Intensity 5", value: 5 },
    { label: "F-Intensity 6", value: 6 },
    { label: "F-Intensity 7", value: 7 },
]; 

export const culetOptions = [
    { label: "Culet 1", value: 1 },
    { label: "Culet 2", value: 2 },
    { label: "Culet 3", value: 3 },
    { label: "Culet 4", value: 4 },
    { label: "Culet 5", value: 5 },
    { label: "Culet 6", value: 6 },
    { label: "Culet 7", value: 7 },
    { label: "Culet 8", value: 8 },
]; 

export const minGirdleOptions = [
    { label: "MinGirdle 1", value: 1 },
    { label: "MinGirdle 2", value: 2 },
    { label: "MinGirdle 3", value: 3 },
    { label: "MinGirdle 4", value: 4 },
    { label: "MinGirdle 5", value: 5 },
    { label: "MinGirdle 6", value: 6 },
    { label: "MinGirdle 7", value: 7 },
    { label: "MinGirdle 8", value: 8 },
    { label: "MinGirdle 9", value: 9 },
]; 

export const maxGirdleOptions = [
    { label: "MaxGirdle 1", value: 1 },
    { label: "MaxGirdle 2", value: 2 },
    { label: "MaxGirdle 3", value: 3 },
    { label: "MaxGirdle 4", value: 4 },
    { label: "MaxGirdle 5", value: 5 },
    { label: "MaxGirdle 6", value: 6 },
    { label: "MaxGirdle 7", value: 7 },
    { label: "MaxGirdle 8", value: 8 },
    { label: "MaxGirdle 9", value: 9 },
]; 