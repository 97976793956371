import React, { useState } from "react";
import { Input, Button, message } from "antd";
import loadingGif from "../../assets/images/powerUserLoading.gif";

const { TextArea } = Input;

const ActiveComp1 = ({ setActiveComp }) => {
  const [loading, setLoading] = useState(false);

  const defaultText = `Keep track of my high churn risk customer accounts based on the following criteria:

Champion Left = True
Regulatory Indicators = Negative
M&A News = Negative

Tag the account in Salesforce and Send slack message to account owner with data and recommended next steps for at risk customers`;

  const handleSave = () => {
    setLoading(true);
    setTimeout(() => {
        setActiveComp(2)
        message.success("Success: Version 1 Built")
    }, 5000)
  };

  return (
    <div className="flex flex-col items-start justify-center h-full px-32 gap-10">
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="linkedTo" className="ml-0.5 text-[1rem]">
          Linked to
        </label>
        <Input id="linkedTo" defaultValue="High Churn Risk Companies" />
      </div>
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="name" className="ml-0.5 text-[1rem]">
          Name
        </label>
        <Input id="name" defaultValue="High Risk Customer Agent" />
      </div>
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="describe" className="ml-0.5 text-[1rem]">
          Decribe what you want the agent to do:
        </label>
        <TextArea
          id="describe"
          defaultValue={defaultText}
          style={{
            height: 180,
            resize: "none",
          }}
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center justify-center w-full">
          {loading && (
            <>
              <img src={loadingGif} alt="loading" width={70} />
              <div>Building Agent</div>
            </>
          )}
        </div>
        <Button onClick={handleSave}>Save & Run</Button>
      </div>
    </div>
  );
};

export default ActiveComp1;
