import React, { useState, useEffect, useRef } from "react";
import "./Audit.css";
import { Button, Card, message, Tooltip } from "antd";
import { Link } from "react-router-dom";
import Joyride from "react-joyride";
import { ChangeUserType } from "../../services/AuthServices";
import {
  DownOutlined,
} from "@ant-design/icons";
import { getAuditReport, getFileStatus } from "../../services/freemiumServices";
import { getTenant } from "../../services/generateListService";
import { Icon } from '@iconify/react';

const Audit = ({ }) => {
  const userDetails = localStorage.getItem('user');
  const [tenant_id, settenant_id] = useState("");
  const [isTenantFetched, setIsTenantFetched] = useState(false);
  const [leads, setLeads] = useState(500);
  const [opportunity, setOpportunity] = useState("10%");
  const [wins, setWins] = useState("50%");
  const [acv, setAcv] = useState("$200");
  const [potRev, setPotRev] = useState(5000);
  const [isARexpanded, setisARexpanded] = useState(false)
  const endOfPageRef = useRef(null);
  const [isTourVisible, setTourVisible] = useState(false);
  const [uniqueAccount, setUniqueAccount] = useState("-");
  const [icpAccounts, setIcpAccounts] = useState("-");
  const [uniqueContacts, setUniqueContacts] = useState("-");
  const [targetPersona, setTargetPersona] = useState("-");
  const [leadsGenerated, setLeadsGenerated] = useState("-");
  const [leadsConverted, setLeadsConverted] = useState("-");
  const [pipelineGenerated, setPipelineGenerated] = useState("-");
  const [revenueGenerated, setRevenueGenerated] = useState("-");
  const [isSampleData, setIsSampleData] = useState(false)
  const [fileStatus, setFileStatus] = useState(false)

  const tourSteps = [
    {
      target: "#sidebar",
      content: "Now you can access premium features from the sidebar",
      placement: "right",
      disableBeacon: true,
    },
  ];
  const handleLeadChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9]/g, "");
    e.target.value = inputValue;

    setLeads(e.target.value);
  };
  const getTenantID = async () => {
    const data = {
      tenant_name: JSON.parse(userDetails).organization_domain
    };
    let response = await getTenant(data);
    console.log(response);
    if(response.status == 200 ){
      settenant_id(response.data?.data.tenantid)
      console.log(response, "responseeeeeeee", tenant_id)
    }
  }
  const getStatus = async (source) => {
    const data = {
      userid: JSON.parse(userDetails).id
    }
    let response = await getFileStatus(data);
    console.log(response);
    if(response.status == 200) {
      if(response.data?.message == "pending"){
        if(source == "click"){
          message.info("UPDATE: We're still processing your data. We will notify you once it's done.")
        }
        setFileStatus(true)
      } else if(response.data?.message == "completed" && source == 'click') {
        message.success("UPDATE: Your data is processed and this is the updated Audit Report")
        setFileStatus(false)
        getReport()
      }
    }
  }
  const getReport = async () => {
    const data = {
      userid: JSON.parse(userDetails).id
    }
    let response = await getAuditReport(data);
    console.log(response);
    if(response.status == 200 ){
      const reportData = response.data?.data;
      setUniqueAccount(reportData.uniqueaccount || "-");
      setIcpAccounts(reportData.icpaccounts || "-");
      setUniqueContacts(reportData.uniquecontacts || "-");
      setTargetPersona(reportData.targetpersona || "-");
      setLeadsGenerated(reportData.leadsgenerated || "-");
      setLeadsConverted(reportData.leadsconverted || "-");
      setPipelineGenerated(reportData.pipelinegenerated || "-");
      setRevenueGenerated(reportData.revenuegenerated || "-");
      setLeads(reportData.uniqueContacts || 500);
      setOpportunity(reportData.roi_percent_opportunitycreated || "10%")
      setWins(reportData.roi_percent_wins || "50%")
      setAcv(reportData.roi_acvroi_potentialrevenue || "$200")
      if(response.data?.message == "SampleData"){
        setIsSampleData(true)
      }
    }
  
}

  const handleOpportunityChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9%]/g, "");

    if (!/%$/.test(inputValue)) {
      inputValue += "%";
    }

    inputValue = inputValue.replace(/%([^%]*)%/, "%$1");

    e.target.value = inputValue;
    setOpportunity(e.target.value);
  };
  const handleWinsChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9%]/g, "");

    if (!/%$/.test(inputValue)) {
      inputValue += "%";
    }

    inputValue = inputValue.replace(/%([^%]*)%/, "%$1");

    e.target.value = inputValue;
    setWins(e.target.value);
  };
  const handleAcvChange = (e) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9$]/g, "");

    if (!/^\$/.test(inputValue)) {
      inputValue = "$" + inputValue.replace(/^\$/, "");
    }

    inputValue = inputValue.replace(/\$([^$]*)\$/, "$1");

    e.target.value = inputValue;
    setAcv(e.target.value);
  };

  useEffect(() => {
    const numericOpportunityValue = Number(opportunity.replace(/[^0-9]/g, ""));
    const numericWinsValue = Number(wins.replace(/[^0-9]/g, ""));
    const numericAcvValue = Number(acv.replace(/[^0-9]/g, ""));

    const actualLeadsConverted = (numericOpportunityValue / 100) * leads;
    const actualOpportunityToWin =
      (numericWinsValue / 100) * actualLeadsConverted;
    const potentialRevenue = actualOpportunityToWin * numericAcvValue;
    setPotRev(potentialRevenue);
  }, [leads, opportunity, wins, acv, potRev]);
  useEffect(() => {
    if (isARexpanded) {
      endOfPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isARexpanded]);
  const handleClaim = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    let response = await ChangeUserType({
      userID: user?.id,
    });
    if (response) {
      console.log(response);
      user.usertype = 'premium';
      localStorage.setItem("user", JSON.stringify(user));
    }
    // setIsPremium(1);
    setTourVisible(true);
  };
  const handleButton = (value) => {
    value == "Audit" ? setisARexpanded(!isARexpanded) : setisARexpanded(isARexpanded)
  };
  useEffect(() => {
    if (!isTenantFetched) {
        getTenantID();
        setIsTenantFetched(true);
    }
    }, [isTenantFetched]);

  useEffect(() => {
    if (JSON.parse(userDetails).id) {
        getReport();
        getStatus("load");
    }
    }, [userDetails]);
    useEffect(() => {
      setLeads(leadsGenerated)
      }, [leadsGenerated]);
    // Function to send a Slack message to a specific user
async function sendMessageToUser(userId, message) {
  try {
    const webhookUrl = 'https://hooks.slack.com/services/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'; // Replace this with your actual webhook URL
    const response = await fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        channel: userId,
        text: message
      })
    });
    const responseData = await response.json();
    console.log('Message sent to Slack user:', userId, responseData);
  } catch (error) {
    console.error('Error sending message to Slack user:', userId, error);
  }
}
  return (
    <>
      <div className="audit_header">Audit Report</div>
      {isSampleData && <>
        <div className="note" style={{color: "red", fontSize: "14px", }}>Note:- This is a Sample Data for your Audit Report as we're still processing your data</div>
        <div className="note" style={{color: "red", fontSize: "14px", marginTop: "-5px"}}> and it might take 12-24 hours. Rest Assured, we'll notify you once it's done.</div>
      </>}
      {fileStatus && !isSampleData && <>
        <div className="note" style={{color: "red", fontSize: "14px", }}>Note:- We're still processing some of your data. We'll notify you once it's done.</div>
        <div className="note" style={{color: "red", fontSize: "14px", marginTop: "-5px"}}> Please <a style={{color: 'blue'}} onClick={()=>getStatus("click")}>click here</a> to refresh the status</div>
      </>}
      {/* <Button className="audit_header pb-6" onClick={() => handleButton("Audit")}>Audit Report</Button> */}
      <>
        <div className="flex pt-6">
        <div className="card_container h-[150px]">
          <Card className="one_third_card bg-[#032D3E] extra_shadow relative">
            <Tooltip title="This figure represents the overall count of accounts within your CRM system." overlayInnerStyle={{textAlign: "justify"}}>
              {/* <Icon icon="ep:info-filled" color="#609da1" width={24} className="absolute top-2 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={24} className="absolute top-2 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <Tooltip title="This number corresponds to the total count of accounts in your CRM system that align with the Ideal Customer Profile (ICP) description you have specified." overlayInnerStyle={{textAlign: "justify"}}>
              {/* <Icon icon="ep:info-filled" color="#609da1" width={24} className="absolute bottom-10 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={24} className="absolute bottom-10 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <div className="card_content text-2xl text-white">{typeof uniqueAccount === 'number' ? Number(uniqueAccount).toLocaleString() : uniqueAccount}</div>
            <div className="card_content text-base text-1xl text-white">
              Accounts
            </div>
            <div className="card_content text-2xl mt-3 text-white">{typeof icpAccounts === 'number' ? Number(icpAccounts).toLocaleString() : icpAccounts}</div>
            <div className="card_content text-base text-1xl text-white">
              ICP Accounts
            </div>
          </Card>
          <Card className="one_third_card bg-[#8DB1BF] extra_shadow relative">
            <Tooltip title="This number signifies the count of distinct email addresses in your CRM system." overlayInnerStyle={{textAlign: "justify"}}>
              {/* <Icon icon="ep:info-filled" color="#032D3E" width={26} className="absolute top-2 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={26} className="absolute top-2 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <Tooltip title="Distinct contacts based on the persona you have specified." overlayInnerStyle={{textAlign: "justify", width: "95%"}}>
              {/* <Icon icon="ep:info-filled" color="#032D3E" width={24} className="absolute bottom-10 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={26} className="absolute bottom-10 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <div className="card_content text-2xl text-black">{typeof uniqueContacts === 'number' ? Number(uniqueContacts).toLocaleString() : uniqueContacts}</div>
            <div className="card_content text-base text-1xl text-black">
              Unique Contacts
            </div>
            <div className="card_content text-2xl mt-3 text-black">{typeof targetPersona === 'number' ? Number(targetPersona).toLocaleString() : targetPersona}</div>
            <div className="card_content text-base text-1xl text-black">
              Relevant Persona
            </div>
          </Card>
          <Card className="one_third_card bg-[#C8C8C8] extra_shadow relative">
            <Tooltip title="Tally the fresh leads resulting from job transitions." overlayInnerStyle={{textAlign: "justify"}} >
              {/* <Icon icon="ep:info-filled" color="#032D3E" width={26} className="absolute top-2 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={26} className="absolute top-2 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <div className="card_content text-2xl text-black">{typeof leadsGenerated === 'number' ? Number(leadsGenerated).toLocaleString() : leadsGenerated}</div>
            <div className="card_content text-base text-1xl text-black">
              New Leads From Job Change
            </div>
          </Card>
        </div>
        <div className="absolute right-0 w-1/4 flex flex-col justify-center items-center gap-3">
          {/* <Link target="_blank" to="https://gtmcopilot.com/"> */}
            <button className="next_btn">
              <span className="next_btn_text">Detailed Report</span>
            </button>
          {/* </Link> */}
          {/* <Link to="/new-leads"> */}
            <button className="next_btn">
              <span className="next_btn_text">View Leads</span>
            </button>
          {/* </Link> */}
          {/* <Link to="/freemium"> */}
            <button
              className="next_btn"
              // onClick={() => setFreemiumActiveTab(1)}
            >
              <span className="next_btn_text">Track More Champions</span>
            </button>
          {/* </Link> */}
          {/* <Button onClick={() => sendMessageToUser("D05EKGPB6J3", "Wassuppppppppp")}> */}
          <Button>
            Slack send
          </Button>
          {/* {!isPremium && ( */}
            <div style={{border: "1px dotted green",  boxShadow: '0 20px 27px rgba(96, 157, 161, 0.3) !important', maxWidth: "200px", padding: "10px"}}>
            <p>
              Claim your workspace to access the full power of GTM CoPilot which includes a freemium tier. 
            </p>
            </div>
          {/* )} */}
          {/* {!isPremium && ( */}
            <button className="next_btn" onClick={handleClaim}>
              <span className="next_btn_text">Claim Workspace</span>
            </button>
          {/* )} */}
        </div>
          </div>
      <div className="flex">
        <div className="card_container mt-10 min-h-[150px]">
          <Card className="one_third_card bg-[#032D3E] extra_shadow relative">
            <Tooltip title="These figures are derived from time-series data, where we examine your CRM lead object to determine the conversion rate of the leads we've supplied." overlayInnerStyle={{textAlign: "justify"}}>
              {/* <Icon icon="ep:info-filled" color="#609da1" width={24} className="absolute top-2 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={24} className="absolute top-2 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <div className="card_content text-2xl text-white">{typeof leadsConverted === 'number' && typeof leadsGenerated === 'number'
          ? `${Number(leadsConverted).toLocaleString()}/${Number(leadsGenerated).toLocaleString()}`
          : `-/-`}</div>
            <div className="card_content text-base text-1xl text-white">
              Leads Converted/Lead Generated
            </div>
          </Card>
          <Card className="one_third_card bg-[#8DB1BF] extra_shadow relative">
            <Tooltip title="The cumulative value of the pipeline generated from the leads we supplied is determined through time-series analysis, examining the opportunity object in your CRM." overlayInnerStyle={{textAlign: "justify"}}>
              {/* <Icon icon="ep:info-filled" color="#032D3E" width={26} className="absolute top-2 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={26} className="absolute top-2 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <div className="card_content text-2xl text-black">{typeof pipelineGenerated === 'number'
          ? `$${Number(pipelineGenerated).toLocaleString()}`
          : pipelineGenerated}</div>
            <div className="card_content text-base text-1xl text-black">
              Pipeline Generated
            </div>
          </Card>
          <Card className="one_third_card bg-[#C8C8C8] extra_shadow relative">
            <Tooltip title="The cumulative value of the revenue generated from the leads we supplied is determined through time-series analysis, examining the opportunity object in your CRM." overlayInnerStyle={{textAlign: "justify"}}>
              {/* <Icon icon="ep:info-filled" color="#032D3E" width={26} className="absolute top-2 right-5 cursor-pointer" /> */}
              <Icon icon="bi:info" color="white" width={26} className="absolute top-2 right-5 cursor-pointer bg-[#609DA1] rounded-xl" />
            </Tooltip>
            <div className="card_content text-2xl text-black">{typeof revenueGenerated === 'number'
          ? `$${Number(revenueGenerated).toLocaleString()}`
          : revenueGenerated}</div>
            <div className="card_content text-base text-1xl text-black">
              Revenue Generated
            </div>
          </Card>
        </div>
      </div>
      </>
      <div onClick={() => handleButton("Audit")} className="audit_header pt-8">ROI Calculator <DownOutlined
        className="dropdown-icon"
        style={{ marginLeft: "2px", height: "6px" }}/></div>
{isARexpanded && 
    < >
      <div ref={endOfPageRef} className="flex">
        <div className="card_container mt-5">
          <Card className="one_third_card bg-[#4B9DA1] h-[150px] extra_shadow">
            <div className="card_content text-xl text-white">Leads</div>
            <div className="flex justify-center mt-2">
              <input
                className="card_content editable_card"
                value={leads}
                onChange={handleLeadChange}
              />
            </div>
          </Card>
          <Card className="one_third_card  bg-[#4B9DA1] extra_shadow">
            <div className="card_content text-xl text-white">
              Percent Opportunity Created
            </div>
            <div className="flex justify-center mt-2">
              <input
                className="card_content editable_card"
                value={opportunity}
                onChange={handleOpportunityChange}
              />
            </div>
          </Card>
          <Card className="one_third_card bg-[#4B9DA1] extra_shadow">
            <div className="card_content text-xl text-white">Percent Wins</div>
            <div className="flex justify-center mt-2">
              <input
                className="card_content editable_card"
                value={wins}
                onChange={handleWinsChange}
              />
            </div>
          </Card>
        </div>
      </div>
      <div ref={endOfPageRef} className="flex">
        <div className="w-3/4 flex pl-52 mt-10 gap-20 mb-5">
          <Card className="one_third_card bg-[#4B9DA1] extra_shadow">
            <div className="card_content text-xl text-white">ACV</div>
            <div className="flex justify-center mt-2">
              <input
                className="card_content editable_card"
                value={acv}
                onChange={handleAcvChange}
              />
            </div>
          </Card>
          <Card className="w-2/3 flex flex-col justify-center items-center bg-[#8DB1BF] extra_shadow h-[150px]">
            <div className="card_content text-4xl">
              <span>$</span>
              <span>{potRev}</span>
            </div>
            <div className="card_content text-base">Potential Revenue</div>
          </Card>
        </div>
      </div>
    </>}
      <Joyride
        steps={tourSteps}
        run={isTourVisible}
        hideCloseButton
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setTourVisible(false);
          }
        }}
      />
    </>
  );
};

export default Audit;
