import React, { useState, useEffect, useRef } from "react";
import {CopyTwoTone, ShareAltOutlined, LikeTwoTone, DislikeTwoTone, DatabaseOutlined, LinkOutlined,  AlignLeftOutlined } from "@ant-design/icons";
import AudienceTypingEffectUtil from "./AudienceTypingEffectUtil";
// import { addLibrary } from "../../services/demo_v2Services";
import { Button, Avatar, Table } from "antd";
import avatar8 from './../../assets/images/user.png';
import sampleChart1 from "../../assets/images/sampleChart1.png";
import sampleChart2 from "../../assets/images/sampleChart2.png";
import sampleChartNew from "../../assets/images/sampleChartNew.png";
import sampleChart3 from "../../assets/images/sampleChart3.png";
import sampleChart4 from "../../assets/images/sampleChart4.png";
import sampleChart5 from "../../assets/images/sampleChart5.png";
import './HomeResChat.css'

import * as XLSX from 'xlsx'
import _ from 'lodash'


const HomeResChat = ({ attachedFile, chatArray, setLoading, source, setIsHistoryPromptClicked, setQuestion, setInputFilled, handleSend, setAttachedFile, setChatArray }) => {
  const latestQuestionRef = useRef(null);
  const chatContentRef = useRef(null);
  const [completed, setCompleted] = useState(false);
  const [fileInformation, setFileInformation] = useState({})
  const [fileMetaInformation, setFileMetaInformation] = useState({})
  const [actualFileData, setActualFileData] = useState([])
  const [isProcessing, setIsProcessing] = useState(false);
  const [fieldArray, setFieldArray] = useState([])

  const isNumberedList = (text) => {
    // Check if the text starts with a number followed by a dot and a space
    const result1 = /^\d+\.\s/.test(text?.trim());
    const result2 = /^(-|\d+\.\s)/.test(text?.trim());
    // console.log(text, result1);
    return result1 || result2;
  };
// const saveLibrarydata = async (data) => {
//   let response = await addLibrary(data);
//   console.log(response);
//   if(response.status == 200 ){
//     console.log("Data Saved ");
//   } else {
//     console.log("error");
//   }
// }
const suggestionButton = (question) => {
  if (question?.toLowerCase()?.trim()?.includes("clean, deduplicate & check for net new records?")) {
    console.log("ejrhgherbgkhebrh")
    setLoading(true)
    setChatArray(prevChatArray => [
      ...prevChatArray,
      {
        question,
        answer: generateAnswerForFileUpload("check").split('\n'),
        isGraph: false,
        suggestions: ["Send net new records to Hubspot lead routing flow?", "Update existing records in CRM with latest information from file?", "Ask questions about the file?"],
        isSuggestions: true
      }
    ])
    return;
  }
  setQuestion(question);
  setInputFilled(true);
  handleSend()
}
  useEffect(() => {
    if (latestQuestionRef.current) {
      setTimeout(() => {
        latestQuestionRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      }, 500)
    }
      // setTimeout(() => {
      //   chatContentRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      // }, 500)
    setCompleted(false)
    if (source === "demo_v2") {
      const chatArrayWithType = chatArray.map(chat => {
        let type = "search";
    
        if (chat.question.includes("Identify niche markets for high-end sports cars focusing on income levels and hobbies")) {
          type = "segment";
        } else if (chat.question.includes("What are the best practices for ensuring high data quality in real-time vehicle data streams?")) {
          type = "dataops";
        } else if (chat.question.includes("Develop a strategy for an automobile manufacturer looking to expand its presence in emerging markets, considering local consumer preferences, regulatory environments, and distribution challenges")) {
          type = "report";
        }
    
        // Returning updated chat object with type
        return { ...chat, type };
      });
    
      const data = {
        user_id: JSON.parse(localStorage.getItem('user'))?.id,
        answer: chatArrayWithType
      };
    
      console.log(data);
      // saveLibrarydata(data);
    }
    
  }, [chatArray]);
  const generateData = () => {
    // console.log(actualFileData)

    // "arr" is  [{ 
    //   [actualFileData[0][0]]: actualFileData[1][0],
    //   [actualFileData[0][1]]: actualFileData[1][1],
    //   [actualFileData[0][2]]: actualFileData[1][2],
    //   ...
    // },
    // { 
    //   [actualFileData[0][0]]: actualFileData[2][0],
    //   [actualFileData[0][1]]: actualFileData[2][1],
    //   [actualFileData[0][2]]: actualFileData[2][2],
    //   ...
    // }, ...]

    // Which means, "arr" has all first values of each row of "actualFileData" as column name. Console log "actualFileData" to better understand the following code
    const arr = []
    // i starting from 1 since i = 0 corresponds to the column names => "SDR", "email", etc.
    for (let i = 1; i < actualFileData?.length; i++) {
      const obj = {}
      for (let j = 0; j < actualFileData[0]?.length; j++) {
        obj[actualFileData[0][j]] = actualFileData[i][j] // Notice key always has i = 0
      }
      arr.push(obj)
    }
    // console.log(arr)
    return arr
  }
  const handleAttachClick = () => {
    document.getElementById("file-upload").click();
  };
  const generateAnswerForFileUpload = (check) => {
    const initialLine = (check === "check") ? "Here is the summary after cleaning and deduplicating:" : "I am analysing the file you have uploaded:"

    // fileMetaInformation contains no. of columns & records
    // fileInformation contains % of missing values in each column

    const fieldsInformation = `1. File has ${fileMetaInformation["No. of Fields"]} columns`
    const recordsInformation = `2. There are ${fileMetaInformation["No. of Records"]} records`
    // const entriesInformation = `3. In total, there are ${fileMetaInformation["No. of Entries"]} entries`

    // Output earlier --------------------------->
    // I am analysing the file you have uploaded:
      // 1. File has 14 columns
      // 2. There are 28 records
      // 3. "SDR" column has no missing values
      // 4. "Time" column has no missing values
      // 5. "Location" column has 78.57% missing values
      // 6. "Rep Needed" column has no missing values
      // 7. "email" column has no missing values
      // 8. "Brand" column has no missing values
      // 9. "Person to meet with Name" column has no missing values
      // 10. "Title" column has no missing values
      // 11. "Cell" column has 3.57% missing values
      // 12. "Opp yes/ no?" column has no missing values
      // 13. "In SFDC?" column has no missing values
      // 14. "Quick Notes from SDR:" column has 85.71% missing values
      // 15. "Have we connected on eTail? y/n" column has 100% missing values
      // 16. "Notes from AE" column has 100% missing values

    // Handled case to show all columns with same percentage of missing values

    const matchingEmptyFields = {}
    for (const [key, value] of Object.entries(fileInformation)) {
      if (!matchingEmptyFields[value]) {
        matchingEmptyFields[value] = []
      }
      matchingEmptyFields[value].push(key)
    }
    // console.log(matchingEmptyFields)

    let rowCount = 3 // Contains count for each new line
    const fieldInfo = Object.entries(matchingEmptyFields)?.map(([ key, value ]) => {
      const lengthOfJoinedColumns = value?.length
      const joinedColumnNames = value?.join(`", "`)
      const info = `${rowCount}. "${joinedColumnNames}" column${lengthOfJoinedColumns > 1 ? 's have' : ' has'} ${key === '0' ? 'no' : key+'%'} missing values`
      rowCount++
      return info
    })?.join("\n")

    // Output After MatchingEmptyFields --------------------------->
    // I am analysing the file you have uploaded:
      // 1. File has 14 columns
      // 2. There are 28 records
      // 3. "SDR", "Time", "Rep Needed", "email", "Brand", "Person to meet with Name", "Title", "Opp yes/ no?", "In SFDC?" columns have no missing values
      // 4. "Have we connected on eTail? y/n", "Notes from AE" columns have 100% missing values
      // 5. "Location" column has 78.57% missing values
      // 6. "Cell" column has 3.57% missing values
      // 7. "Quick Notes from SDR:" column has 85.71% missing values

    const duplicates = `${rowCount}. ${(check === "check") ? "There are no duplicates left" : "There are 12% duplicate rows in the file"}`
    rowCount += 1

    const extra = `${(check === "check") ? `${rowCount}. We found out there are 20 new contacts based on emails & 10 new contacts based on URL` : ""}`

    const answer = initialLine + "\n" + fieldsInformation + "\n" + recordsInformation + "\n" + fieldInfo + "\n" + duplicates + "\n" + extra

    // const answer = `I am analysing the file you have uploaded:\n1. File has 5 columns\n2. company_name column represent name of companies and has no missing values\n3. email column represents emails of individual, it is a PII filed and has 20% missing values\n4. website column represents URL's with 10% missing values\n5. contact_name represents name of individuals with no missing values\n6. company_country represents country with no missing values.\n7. There are 12% duplicate rows in the file`
    return answer
  }
  const readExcel = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        // console.log(workbook.SheetNames, "workbook.SheetNames")

        const sheetName = workbook.SheetNames[0]
        // console.log(workbook.Sheets, "workbook.Sheets")
        const sheet = workbook.Sheets[sheetName]
        const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 })
        resolve(excelData)
      }
      reader.onerror = (error) => {
        reject(error)
      }
      reader.readAsArrayBuffer(file)
    })
  }
  const handleDataOps = async (fileName, file) => {
    console.log(fileName)
    setLoading(true)
    setChatArray(prevChatArray => [
      ...prevChatArray,
      {
        question: `You've uploaded ${fileName}`,
        suggestions: []
      }
    ])
    try {
      if (file) {
        // console.log(file)

        // -------------------------- File Handling Starts -------------------------------------
        const excelData = await readExcel(file)
        // console.log(excelData)
        let validData = []
        // validData = All the data before we encounter an empty row in the uploaded file

        for (let i = 0; i < excelData?.length; i++) {
          if (excelData[i]?.length === 0) {
            break;
          } else {
            validData?.push(excelData[i])
          }
        }
        // console.log(validData)
        setActualFileData(validData)
        const columnarData = _.zip(...validData)
        // columnarData = Transformed data from validData, that is, all rows are transformed to columns & all columns are transformed to rows. Console log both these values to better understand this

        // console.log(columnarData)
        const fields = columnarData?.length
        const records = columnarData[0]?.length - 1 // First entry of each row is the header

        // const totalCells = records * fields
        // console.log("No. of Fields ", fields)
        // console.log("No. of Records ", records)
        // console.log("No. of Cells ", totalCells)
        const metaInfo = {
          "No. of Fields": fields,
          "No. of Records": records
        } // no. of fields, records to give to fileMetaInformation state
        setFileMetaInformation(metaInfo)

        let emptyTracker = {} // "Field Name" (mapped to) No. of empty values in this field/column
        let missingColNameCount = 1 // Handling for Empty Column Header Names => "Un-Named Column 1"
        for (let i = 0; i < columnarData?.length; i++) {
          const rowData = columnarData[i]
          let emptyCount = 0;
          for (let j = 1; j < rowData?.length; j++) {
            const cellData = rowData[j]
            if (!cellData || cellData?.toString()?.cellData?.trim() === "") {
              emptyCount++
            }
          }
          let percentageEmptyValues = 100 // For cases where just the column headers are defined & there are no records
          if (records !== 0) { // Dividing by 0 results in NaN
            percentageEmptyValues = emptyCount / records * 100
          }
          let columnName = rowData[0]
          if (!columnName || columnName?.toString()?.trim() === "") { // Handle case when field name is empty
            columnName = 'Un-Named Column ' + missingColNameCount
            missingColNameCount++
          }
          emptyTracker[columnName] = parseFloat(percentageEmptyValues.toFixed(2)) // Rounding off to 2 decimal places & converting to float value instead of string value
        }
        // console.log(emptyTracker)
        setFileInformation(emptyTracker)
        // -------------------------- File Handling Ends -------------------------------------

        setIsProcessing(true);
        setInputFilled(true)
        setTimeout(() => {
          setIsProcessing(false);
          setAttachedFile(file);
        }, 2000);
      }
    } catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    setChatArray(prevChatArray => {
      const lastIndex = prevChatArray.length - 1
      return [
      ...prevChatArray.slice(0, lastIndex),
      {
        ...prevChatArray[lastIndex],
        answer: generateAnswerForFileUpload().split('\n'),
        isGraph: false,
        suggestions: ["Clean & deduplicate file?", "Clean, deduplicate & check for net new records?", "Ask questions about the file?"],
        isSuggestions: true
      }
    ]})
  }, [fileInformation])
  const handleFileChange = (e) => {
    setAttachedFile(e.target.files[0]);
    const fileName = e.target.files[0]?.name
    // setInputFilled(true)
    // setQuestion("You've uploaded a file")
    handleDataOps(fileName, e.target.files[0])
    // handleSend()
  };
  function renderLink(text) {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let match;
    let lastIndex = 0;
    const elements = [];
  
    while ((match = linkRegex.exec(text)) !== null) {
      const linkText = match[1];
      const linkUrl = match[2];
  
      // Add the text before the link
      elements.push(
        <span key={`text-${lastIndex}`}>
          {text.substring(lastIndex, match.index)}
        </span>
      );
  
      // Add the link
      elements.push(    
        <a
          key={`link-${match.index}`}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#609DA1', textDecoration: 'underline' }}
        >
          {linkText}
        </a>
      );
  
      lastIndex = linkRegex.lastIndex;
    }
  
    // Add the remaining text after the last link
    if (lastIndex < text.length) {
      elements.push(
        <span key={`text-${lastIndex}`}>
          {text.substring(lastIndex, text.length)}
        </span>
      );
    }
  
    return <div className="inline">{elements}</div>;
  }
  return (
    <div className="w-[107%] flex flex-col">
      {source == "history" && <Button onClick={() => setIsHistoryPromptClicked(false)} className="mb-2 border w-[fit-content]">Go back to Your Library</Button>}
      {chatArray?.map((chat, index) => (
        <>
        <div className="flex ">
          <div className={chat.isGraph === true ? "w-[90%] mb-10" : "w-[90%] mb-10"} key={index}>
          <div className="flex gap-2 mb-1 mt-2" style={{ alignItems: "center", marginLeft: "-30px" }}>
            <div style={{ height: "40px", width: "40px" }} className="flex justify-center items-center">
              <Avatar 
                src={localStorage.getItem('profile_url') ? localStorage.getItem('profile_url').toString() : avatar8}
              ></Avatar>
            </div>
            <div className="text-2xl ml-2">
              {chat?.question}
            </div>
          </div>

            <div className="flex items-center gap-2.5 mt-6 mb-2">
              <AlignLeftOutlined style={{ fontSize: "24px" }} />
              <div ref={
                index === chatArray.length - 1 ? latestQuestionRef : null
              } className="flex justify-center items-center text-2xl pb-0.5">
                Answer
              </div>
            </div>
            {(index !== chatArray?.length - 1 || completed) && chat?.answer?.map((ans, ansIndex) => (
              <div key={`ans_${ansIndex}`} style={{ paddingLeft: isNumberedList(ans) ? "70px" : "2.5rem"}} className={` ${isNumberedList(ans) && 'my-2'} text-justify text-base`}>
                {isNumberedList(ans) ? (
                  <div className={`flex gap-${Number(ans?.split(" ")[0]?.split(".")[0]) < 10 ? '5' : '3'}`}>
                    <div className="text-end">
                      {ans?.split(" ")[0]?.trim()}
                    </div>
                    <div>
                      {renderLink(ans?.split(" ").slice(1).join(" ").trim())}
                    </div>
                  </div>
                ) : (
                  <>{renderLink(ans?.trim())}</>
                  // <>{ans?.trim()}</>
                )}
              </div>
            ))}
            {index === chatArray?.length - 1 && !completed && 
              <>
                <AudienceTypingEffectUtil 
                  chatArray={chatArray} 
                  isNumberedList={isNumberedList} 
                  renderLink={renderLink} 
                  setCompleted={setCompleted}
                  chatContentRef={chatContentRef}
                  setLoading={setLoading}
                  fieldArray={fieldArray}
                  setFieldArray={setFieldArray}
                  setAttachedFile={setAttachedFile}
                /> 
                <div ref={chatContentRef} />
              </>
            }

            {/* {(chat?.question.toLowerCase().trim().includes("you've uploaded") && completed) ?
              <Table bordered className="w-[95%] h-[25rem] demoAppTable" style={{overflow: "scroll"}}
                columns={fieldArray?.map((field, index) => (
                  { title: field, dataIndex: field, key: index }
                ))}
                dataSource={generateData()}
                pagination={false} // If you want pagination, remove this line
              /> : <></>} */}

            {completed && (
              <>
              {!attachedFile && (
                chat?.question.toLowerCase().trim().includes("revenue down this week") ? 
                // <img className="mt-4 ml-8" src={sampleChart1} /> 
                <iframe 
                  className="mt-4 ml-8" 
                  src={"https://visualize.graphy.app/view/ae6d2888-c790-40f9-bc0c-2f1357c53dc7"} 
                  width="50%" 
                  height="400" 
                  frameBorder="0"
                ></iframe>
                :
                chat?.question.toLowerCase().trim().includes("drop in influencer partnership") ?
                <img className="mt-4 ml-8" src={sampleChart2} /> :
                chat?.question.toLowerCase().trim().includes("can i improve performance") ?
                <img className="mt-4 ml-8" src={sampleChartNew} /> :
                chat?.question.toLowerCase().trim().includes("campaign perform last week?") ?
                <img className="w-[70%] mt-4 ml-8" src={sampleChart3} /> :
                chat?.question.toLowerCase().trim().includes("active users?") ?
                <img className="mt-4 ml-8" src={sampleChart4} /> : 
                chat?.question.toLowerCase().trim().includes("sales revenue this week?") ?
                <img className="mt-4 ml-8" src={sampleChart5} /> :<div style={{display: "none"}}></div> 
              )}
              <>
                {
                  (chat?.isSuggestions === true) && (chat?.suggestions[0] !== "Attach") ? 
                  <div className="ml-10 pt-6 color-[gray]">
                    Suggestions:-
                  </div> : "" 
                }
 
                <div className="flex gap-2 ml-10 mt-2 flex-wrap">
                  {chat?.suggestions?.map((suggestion, index) =>(
                    <React.Fragment key={index}>
                      {suggestion === "Attach" ? (
                        <label htmlFor="file-upload" className="cursor-pointer">
                          <Button onClick={handleAttachClick}>{suggestion}</Button>
                          <input
                            id="file-upload"
                            type="file"
                            accept=".csv"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                          />
                          
                        </label>
                      ) : (
                      // ) : suggestion === "ChooseApp" ? (<div className="flex justify-content items-center">
                      //   <Radio onClick={() => suggestionButton("Send me alerts on Slack")}>Slack</Radio>
                      //   <Radio onClick={() => suggestionButton("Send me alerts on Mail")}>Email</Radio>
                      //   <Radio onClick={() => suggestionButton("Send me alerts on CE")}>Chrome Extension</Radio>
                      // </div>) : (
                        <Button onClick={() => suggestionButton(suggestion.replace('+disabled', ''))} disabled={suggestion.includes('+disabled')} 
                        // style={{ whiteSpace: 'normal', height: 'auto', lineHeight: suggestion?.split(" ")?.length > 5 ? '20px' : '40px' }}
                        >
                        {suggestion.replace('+disabled', '')}
                      </Button>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </>
              </>
            )}

            
          </div>
        {/*  {chat.isGraph === true && (
              <div className="flex justify-center w-[45%] mt-10 ml-2" style={{height: "fit-content"}}> 
            {chat?.question.toLowerCase().trim().includes("revenue down this week") ? 
              <Table className="demoAppTable"
              columns={[
                { title: 'Date', dataIndex: 'date', key: 'date'},
                { title: 'Revenue', dataIndex: 'revenue', key: 'revenue' },
              ]}
              dataSource={[
                { date: "December'29", revenue: 7800 },
                { date: "January'05", revenue: 15500 },
                { date: "January'12", revenue: 9000 },
                { date: "January'19", revenue: 18000 },
                { date: "January'26", revenue: 11000 },
                { date: "February'03", revenue: 28000 },
              ]}
              pagination={false}
            /> :
              chat?.question.toLowerCase().trim().includes("drop in influencer partnership") ?
            
              <Table className="demoAppTable"
              columns={[
                { title: 'Social Media', dataIndex: 'sm', key: 'sm' },
                { title: 'User Count', dataIndex: 'uc', key: 'uc' },
              ]}
              dataSource={[
                { sm: "Facebook", uc: 730000 },
                { sm: "Linkedin", uc: 65000 },
                { sm: "Twitter", uc: 4000 },
                { sm: "Instagram", uc: 1000 },
              ]}
              pagination={false}
            /> :
            
            
            
            
              chat?.question.toLowerCase().trim().includes("sales revenue this week?") ?
            
              <Table className="w-[60%] demoAppTable" style={{overflowX: "scroll"}}
                columns={[
                  { title: 'Date', dataIndex: 'date', key: 'date' },
                  { title: 'Paid Ads', dataIndex: 'paidAds', key: 'paidAds' },
                  { title: 'Influencer Partnership', dataIndex: 'influencerPartnership', key: 'influencerPartnership' },
                  { title: 'SmartHome Essentials', dataIndex: 'smartHomeEssentials', key: 'smartHomeEssentials' },
                  { title: 'Tech Gadgets', dataIndex: 'techGadgets', key: 'techGadgets' },
                  { title: 'Others', dataIndex: 'others', key: 'others' },
                ]}
                dataSource={[
                  { date: "December'29", paidAds: 100, influencerPartnership: 75, smartHomeEssentials: 50, techGadgets: 25, others: 15 },
                  { date: "January'15", paidAds: 100, influencerPartnership: 75, smartHomeEssentials: 50, techGadgets: 25, others: 15 },
                  { date: "January'31", paidAds: 100, influencerPartnership: 75, smartHomeEssentials: 50, techGadgets: 25, others: 15 },
                ]}
                pagination={false}
              />
              :<div style={{display: "none"}}></div>
            }
            </div>
          )} */}
        </div>
        <div>
          {(completed || index !== chatArray?.length - 1) && (
              <>
                {chat?.sources && chat?.sources?.length > 0 && chat?.sources?.some(Boolean) && (
                  <>
                  <div className="flex items-center gap-2.5 mb-2">
                    <DatabaseOutlined style={{ fontSize: "24px" }} />
                    <div className="flex justify-center items-center text-2xl pb-0.5">
                      Sources
                    </div>
                  </div>
                  <div className="flex flex-wrap pl-[3rem] justify-start items-center gap-5 pb-2 w-[90%]">
                    {chat?.sources?.map((source, sourceIdx) => (
                      source !== false && source?.title && (
                        <div 
                          key={`source-${sourceIdx}`} 
                          className="flex flex-col justify-center items-center border rounded-lg w-[300px] h-[100px] gap-5 cursor-pointer border-[#609DA1] bg-gray-100 hover:scale-105 delay-150 duration-300 ease-in-out" 
                          onClick={() => window.open(`${source?.url}`, "_blank", "noreferrer")}
                        >
                          <div className="flex justify-start items-center w-[90%] text-lg">
                            {source?.title?.length > 30 ? source?.title?.substring(0, 30) + "..." : source?.title}
                          </div>
                          <div className="flex justify-start items-center gap-2 w-full pl-[0.75rem]">
                            <div className="flex justify-center items-center border border-[#609DA1] rounded-full p-2 bg-white">
                              {source?.image?.includes("http") ? (
                                <img alt="img" src={source?.image} width={20} />
                              ) : (
                                <LinkOutlined style={{ color: 'black', fontSize: "20px" }} />
                              )}
                            </div>
                            <div className="text-[12px]">
                              {source?.siteName || "Source"}
                            </div>
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                  </>
                )}
              {chat?.suggestions[0] !== "Attach" && (
                <div className="mt-4" style={{ paddingLeft: "2.5rem", marginBottom: "20px", display: "flex", gap: "8px" }} >
                  <CopyTwoTone twoToneColor={"#609da1"} className="copy-icon icon" />
                  <LikeTwoTone twoToneColor={"#609da1"}  className="like-icon icon" />
                  <DislikeTwoTone twoToneColor={"#609da1"}  className="dislike-icon icon" />
                  {/* <DownloadOutlined style={{ color: "#609da1", fill: 'white' }} className="download-icon icon" /> */}
                  <ShareAltOutlined style={{ color: "#609da1", fill: 'white' }} className="download-icon icon" />
                </div>
              )}
              </>
            )}
        </div>
        </>
      ))}
    </div>
  );
};

export default HomeResChat;
