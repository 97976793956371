import React, { useState, useEffect, useRef } from "react";
import sheets from "../../assets/images/sheets.png";
import salesforceImg from "../../assets/images/salesforceImg.png";
import { Button, Card, Upload, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { SyncOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { shareDataHelper } from "../../helpers/ShareDataHelper";
import Loading from "../Loading/Loading";
import { getTenant } from "../../services/generateListService";
import { Icon } from "@iconify/react";
import { integrationStatus } from "../../services/integrationServices";
import { airbyteDisconnection, airbyteSync } from "../../services/airbyteServices"
import { useLocation } from "react-router-dom";
// import { addCalendarEvents } from "../../services/GoogleAPI";

import "./ShareData.css";

const ShareData = ({ setActiveTab, isPremium }) => {
  const navigate = useNavigate();
  const [activeDest, setActiveDest] = useState(0);
  const [uploadListVisible, setUploadListVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [messageFileName, setMessageFileName] = useState("");
  const userDetails = localStorage.getItem("user");
  const [tenant_id, settenant_id] = useState("");
  const [csvData, setCsvData] = useState(null);
  const [salesforceConnected, setSalesforceConnected] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const codeParam = queryParams.get("GCcode");
  const prevcodeParamRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (codeParam !== null && codeParam !== prevcodeParamRef.current) {
          prevcodeParamRef.current = codeParam;
          // const accessToken = await obtainAccessToken(codeParam);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [codeParam, prevcodeParamRef]);
  // const obtainAccessToken = async (decodedCode) => {
  //   try {
  //     const response = await fetch('https://www.googleapis.com/oauth2/v4/token', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //       },
  //       body: new URLSearchParams({
  //         grant_type: 'authorization_code',
  //         client_secret: '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',
  //         redirect_uri: "https://app.gtmcopilot.com/api_v3/gCalendarRedirectFree",
  //         code: decodedCode,
  //         client_id: '493279028413-iljm3o2ifij9cal9m54gnlvjr7krg3jo.apps.googleusercontent.com',
  //       }),
  //     });     
  
  //     const data = await response.json();
  //     const accessToken = data.access_token;
      
  //     await getCalendarEvent(accessToken);
  //   } catch (error) {
  //     console.error('Error obtaining access token:', error);
  //     throw error;
  //   }
  // };
  // const getCalendarEvent = async (accessToken) => {
  //   try {
  //     const calendarResponse = await fetch('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     const calendarData = await calendarResponse.json();
  //     console.log('Calendar Data:', calendarData);
  //     if (calendarData.items) {
  //       console.log('Formatted Calendar Data:', calendarData.items);
  //       postCalendarData(calendarData.items)
  //     } else {
  //       message.error('No events found in the calendar.');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching calendar data:', error);
  //   }
  // };
  // const postCalendarData = async (calendarData) => {
  //   const data = {
  //     user_id: JSON.parse(userDetails)?.id,
  //     calendarDataItems: calendarData
  //   };
  //   let response = await addCalendarEvents(data);
  //   if (response.status == 200) {
  //      message.success("Calendar Data Synced")
  //     }
  //     // console.log(connectedApps, "ConnectedApps");
  // };
  const getIntegrationStatus = async () => {
    const data = {
      userid: JSON.parse(userDetails)?.id,
      source: "freemium"
    };
    let response = await integrationStatus(data);
    if (response.status == 200 && response.data.data.includes('Salesforce')) {
       setSalesforceConnected(true)
       setActiveDest(2)
      }
      console.log(salesforceConnected, "ConnectedApps");
  };
  useEffect(() => {
    getTenantID();
    getIntegrationStatus();
  }, []);

  useEffect(() => {
    console.log(loading);
  }, [loading]);
  const disconnectAirbyte = async () => {
    const data = {
        user_id: JSON.parse(userDetails)?.id,
        user_name: JSON.parse(userDetails)?.name?.replace("|", " "),
        integration_name: 'Salesforce'
    };
    let response = await airbyteDisconnection(data);
    // console.log(response);
    if (response.status == 200) {
      message.success("Salesforce disconnected successfully")
      setSalesforceConnected(false)
      setTimeout(() => {
        const reloadURL = window.location.href.split('?')
        window.location.href = reloadURL[0]
        window.location.reload()
      }, 500);
    }
  };
  // useEffect(() => {
  //   console.log(tenant_id);
  //   console.log(JSON.parse(userDetails)?.id);
  // }, [tenant_id]);
  const onNextClick = () => {
    if (messageFileName || salesforceConnected) {
      window.location.href = "/audit"
      // navigate("/audit");
    } else {
      message.error("Please Upload a CSV or Connect to Salesforce to see the Audit Report");
    }
  };
  const handleCardClick = (index) => {
    if (index === 0) {
      setActiveDest(1);
    } else if( index === 1 ){
      if(!salesforceConnected){
        setActiveDest(2);
        window.location.href = 'https://app.gtmcopilot.com/api_v3/oauth/login?tenant_id='+localStorage.getItem("tenant_id")+'&user_name='+JSON.parse(userDetails)?.name?.replace("|", " ")+'&email='+JSON.parse(userDetails)?.email+'&tenant_name='+JSON.parse(userDetails)?.organization_domain+'&source=salesforceSD&user_id='+JSON.parse(userDetails)?.id;
      } else {
          disconnectAirbyte()
      }    
    } else{
      window.location.href = 'https://accounts.google.com/o/oauth2/auth?client_id=493279028413-iljm3o2ifij9cal9m54gnlvjr7krg3jo.apps.googleusercontent.com&redirect_uri=https://app.gtmcopilot.com/api_v3/gCalendarRedirectFree&scope=https://www.googleapis.com/auth/calendar.readonly&response_type=code&access_type=offline'
    }
  };
  const syncAirbyte = async () => {
    const data = {
        userid: JSON.parse(userDetails)?.id,
        username: JSON.parse(userDetails)?.name?.replace("|", " "),
        tenantname: JSON.parse(userDetails)?.organization_name,
        tenantid: localStorage.getItem('tenant_id'),
        source_name: "Salesforce"
    };
    let response = await airbyteSync(data);
    // console.log(response);
    if (response.status == 200) {
      message.success("Salesforce synced successfully")
    }
  };
  const getTenantID = async () => {
    const data = {
      tenant_name: JSON.parse(userDetails)?.organization_domain,
    };
    let response = await getTenant(data);
    // console.log(response);
    if (response.status == 200) {
      settenant_id(response.data?.data.tenantid);
      localStorage.setItem('tenant_id', response.data?.data.tenantid)
      // console.log(response, "responseeeeeeee", tenant_id)
    }
  };
  const downloadSampleCSV = () => {
    // Replace 'path/to/sample.csv' with the actual path to your sample CSV file
    const sampleCSVPath = '/SampleCSV.csv';
    
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = sampleCSVPath;
    anchor.download = 'sample.csv';
    
    // Append the anchor element to the document and trigger the download
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  useEffect(() => {
    if (messageFileName !== "" && messageFileName !== "salesforceNac") {
      const currentDateTime = new Date()
        ?.toISOString()
        ?.replace(/[-:.TZ]/g, "")
        ?.replace("T", "-")
        ?.slice(0, 14);

      const createdDate = new Date(Date.now()).toLocaleString("en-US", {
        timeZone: "America/New_York",
        timeZoneName: "short",
      });

      const filename = `f-${currentDateTime}`;
      const filteredData = csvData?.data?.map((row) => {
      if(row?.FirstName && row?.LastName && row?.AccountName){
        return {
          fname: row?.FirstName,
          lname: row?.LastName,
          accountname: row?.AccountName,
          website: row?.Website,
          country: row?.BillingCountry,
          job_title: row?.JobTitle,
          account_id: row?.AccountID,
          contact_id: row?.ContactID,
          salutation: row?.Salutation,
          email: row?.Email,
          filename: filename,
          createddate: createdDate,
          updateddate: createdDate,
          userid: JSON.parse(userDetails)?.id,
          tenantid: tenant_id,
        };
      }
      });
      // console.log(filteredData);
      setUploadListVisible(true);
      shareDataHelper(filteredData, loading, setLoading, messageFileName);
    }
    
  }, [messageFileName]);
  useEffect(() => {
    if(localStorage.getItem('sfcode') == 'true'){
      message.success(
        <div>
          <p>Thanks for submitting! Your data is processing and can take up to 24 hours to process, but feel free to check in a few hours to see what progress we have made.</p>
          <p>We'll email you when your data has finished processing with a link to your dashboard.</p>
        </div>,
        15
      );
    localStorage.removeItem('sfcode')
    setMessageFileName('salesforceNac')
    }
    else if(localStorage.getItem('sfcode') == 'false'){
      message.error("Cannot connect at this moment")
      localStorage.removeItem('sfcode')
    }
  }, [setActiveTab])
  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    beforeUpload: (file) => {
      if (file) {
        const isCSV = file.type === "text/csv" || file.name.endsWith(".csv");
        if (isCSV) {
          // console.log(file);
          Papa.parse(file, {
            complete: (result) => {
              // console.log(result.data);
              setCsvData(result);
              const requiredFields = [
                "FirstName",
                "LastName",
                "AccountName",
                "Website",
                "BillingCountry",
              ];
              const missingFields = requiredFields?.filter(
                (field) => !result?.meta?.fields?.includes(field)
              );

              if (missingFields?.length > 0) {
                const fieldWord =
                  missingFields?.length === 1 ? "field" : "fields";
                const missingFieldNames = missingFields?.map((fieldName) => (
                  <span className="font-bold">{fieldName}</span>
                ));

                message.error(
                  <span>
                    File does not contain the required {fieldWord}:{" "}
                    {missingFieldNames.reduce((prev, curr) => [
                      prev,
                      ", ",
                      curr,
                    ])}
                    . Please check your CSV file.
                  </span>
                );
                setUploadListVisible(false);
                return;
              } else {
                if(messageFileName == "" ){
                  setMessageFileName(file.name);
                } else {
                  message.error("You've already synced")
                }
              }
            },
            header: true,
          });
          return false;
        } else {
          message.error("Please upload a CSV file.");
        }
      }
    },
    onRemove: (file) => {
      // message.success(`File ${file.name} removed.`)
    },
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="flex justify-center mr-4 items-center py-12 px-12 font-roboto gap-16">
        {/* <div className="flex flex-col gap-5"> */}

          <Card
            key={0}
            className={`shareDataCard shadow-md cursor-default ${
              activeDest === 1 ? "shareDataCardActive" : ""
            } ${messageFileName != 'salesforceNac' && messageFileName != "" ? 'success-border' : ''} `}
          >
            <div className="flex flex-col justify-start items-center min-h-[150px] w-[250px] gap-2" >
              <img src={sheets} alt="cardImg 0" className="w-[50px]" />
              <p className="text-[13px] font-roboto font-black self-start" style={{paddingTop: "1rem"}}>
                Track Champions or Enrich a list: Upload CSV
              </p>
              <div className="w-full flex flex-col justify-center items-center">
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon icon="radix-icons:dot-filled" width="10" />
                  <div className="text-justify">
                    Track champions to see if they have
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-justify">
                   changed companies, if so we’ll give you
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-justify">
                   their new lead information or simply enrich
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-justify">
                   a list so you have a complete and accurate
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-justify">
                   set of data.
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                  <Icon icon="radix-icons:dot-filled" width="10" />
                  <div className="text-justify">
                    Salesforce connection is not required
                  </div>
                </div>
                {/* <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-justify">required</div>
                </div> */}
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon icon="radix-icons:dot-filled" width="10" />
                  <div className="text-justify">
                    {/* Please upload CSV file with only these */}
                    Please upload CSV file with at least  
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-justify">
                    these fields - Contact ID (optional),
                  </div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-start">First Name, Last Name, Account Name</div>
                </div>
                <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                  <Icon
                    icon="radix-icons:dot-filled"
                    width="10"
                    color="white"
                  />
                  <div className="text-start"> </div>
                </div>
              </div>
              <div className="flex justify-between items-center gap-16">
        <span onClick={() => downloadSampleCSV()} style={{marginTop: "13px"}} className="shareDataUploadBtn text-[12px] text-white bg-[#609da1] cursor-pointer font-roboto self-start border border-[#609da1] px-5 rounded">
          Download Sample
        </span>
        <Upload.Dragger
          {...props}
          maxCount={1}
          showUploadList={uploadListVisible}
          className="shareDataUpload"
           style={{marginTop: "10px"}}
        >
        <span onClick={() => handleCardClick(0)} className="shareDataUploadBtn text-[17px] text-white bg-[#609da1] cursor-pointer font-roboto self-end border border-[#609da1] px-5 rounded">
          Upload
        </span>
        </Upload.Dragger>

      </div>
            </div>
          </Card>{" "}
        {/* <div className="text-[red] text-justify">
            Please Upload CSV File With These Exact Field Names:
            <br />
            <span className="underline">fname</span>,{" "}
            <span className="underline">lname</span>,{" "}
            <span className="underline">accountname</span>,{" "}
            <span className="underline">website</span>,{" "}
            <span className="underline">country</span>
          </div> */}
        {/* </div> */}

        {/* <div className="flex flex-col gap-5"> */}
        {/* <Link
            to="https://login.salesforce.com/?ec=302&startURL=%2Fsetup%2Fsecur%2FRemoteAccessAuthorizationPage.apexp%3Fsource%3DCAAAAYvWG5JWMDAwMDAwMDAwMDAwMDAwAAAA9kAQZfb4Yi4m1UQsSDEkfc7BFq43m-d-Sg03YdTD-mVnOpO_fYuXW4SnjW4C-h0I1dtoJtbk5CzKdZHD5T6a5aqZtZYwHekr1ulOrTyKPUA7gyiEMj9fTd3KbgLYxL2ZPKFYf-KZ7lavj4rACGHC1a2xAAvWLcBs6ZavSW4pSjSbET_ZODHDc-PPOdW89gZPfykDcJDSjXv4SoJ5JxTwJttxYdjMS-n3Tn3NtMZIeEn6bBFEaZEPim2-8K2iGbIxaFgE29WjcdU_7GhqUw0heh6fnA_c--VW4rcicoct3mW6wUtHyWuVTcsBOOmRFnjEveL1_k0tX9WUaUTS_0oTAub3oQS-4o8xkhDsx90uJZAdPouO8N1hZCFv9yJBSt6JB1pC8wc-nBDp-0qrdUPrgA3m2OO-7yFvuwm_RuDFwBNZWIlgxfOp16mc97Zzi97bqOQ4UCu_nAIIlHcNTCrJct_tHkkgSMNaBNSxEZ4f7GGQuUjC2uv1rcqTjKUJJ96qaPFkKsPV5kLFn0_6g6quTdmbLz1-RMSphN-Q9WiMCQWgUh_anrtAIdVogRoy1YIRalMxsCt4zRg3xJm4xZWw5Rk69tp1ioUFHFIZptC2fDjxmo8CAq-IHvLlQWwMDx-zWB9eHTlYYj8Fr2aJwU1juHY%253D"
            target="_blank"
          > */}
          
        <Card
          key={1}
          className={`shareDataCard shadow-md cursor-default ${
            activeDest === 2 ? "shareDataCardActive" : ""
          } ${messageFileName === 'salesforceNac' ? 'success-border' : ''}`}
          
        >
          <div className="flex flex-col justify-start items-center min-h-[150px] w-[250px] gap-1">
            <img src={salesforceImg} alt="cardImg 1" className="w-[100px]" />
            <p className="text-[13px] font-roboto font-black self-start mt-2">
              Recommended: Connect to Salesforce
            </p>
            <div className="w-full flex flex-col justify-center items-center">
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon icon="radix-icons:dot-filled" width="10" />
                <div className="text-justify">Track champions to see if they have</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">changed companies, if so we’ll give you</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">their new lead information or simply enrich</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">CRM data.</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon icon="radix-icons:dot-filled" width="10" />
                <div className="text-justify">
                  Get a free CRM audit to get an
                </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">understanding of how much of your data</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">is out of date or incomplete.</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon icon="radix-icons:dot-filled" width="10" />
                <div className="text-justify w-full">
                  We exclusively access & read data from
                </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">
                 your CRM; at no point do we edit or modify
                </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5 mb-8">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">
                   or modify your data
                </div>
              </div>
              {/* <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify text-white">
                  dummy data for space
                </div>
              </div> */}
            </div>
            {/* <p className="text-[12px] text-[#999999] bg-neutral-100 font-roboto self-end border px-5 mt-1 rounded cursor-not-allowed">
              Connect
            </p> */}
            {salesforceConnected && 
              <p style={{marginTop: '-7.5%', display: "flex", alignItems: "center", padding: "4px"}} onClick={syncAirbyte} className="shareDataUploadBtn text-[10px] text-white bg-[#609da1] cursor-pointer font-roboto self-start border border-[#609da1] px-5 rounded right-5 bottom-2.5">
                <SyncOutlined style={{fontSize: "0.9rem"}} />
              </p>
            }
            <p onClick={() => handleCardClick(1)} className="shareDataUploadBtn text-[16px] text-white bg-[#609da1] cursor-pointer font-roboto self-end border border-[#609da1] px-5 rounded absolute right-5 bottom-2.5">
              {salesforceConnected ? "Disconnect" : "Connect"}
            </p>
          </div>
        </Card>
        {/* </Link> */}
        {/* <Card
          key={1}
          className={`shareDataCard shadow-md cursor-default ${
            activeDest === 2 ? "shareDataCardActive" : ""
          } ${messageFileName === 'salesforceNac' ? 'success-border' : ''}`}
          
        >
          <div className="flex flex-col justify-start items-center min-h-[150px] w-[250px] gap-1">
            <img src={googleCalendar} alt="cardImg 1" className="w-[85px]" />
            <p className="text-[13px] font-roboto font-black self-start mt-2">
             Connect your Calendar
            </p>
            <div className="w-full flex flex-col justify-center items-center">
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon icon="radix-icons:dot-filled" width="10" />
                <div className="text-justify">By granting access, you allow us to</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">read events and information about </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">meeting participants in your calendar.</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon icon="radix-icons:dot-filled" width="10" />
                <div className="text-justify">
                We take the security and privacy of
                </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">your data seriously. Rest assured that we</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">will only access the necessary calendar</div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify"> information required for enhancing </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center text-justify justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">your experience with GTMC. </div>
              </div>
              
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon icon="radix-icons:dot-filled" width="10" />
                <div className="text-justify w-full">
                Your data will be handled with the
                </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">
                 utmost confidentiality and will not
                </div>
              </div>
              <div className="flex text-[13px] font-roboto w-full items-center justify-start gap-0.5 mb-8">
                <Icon
                  icon="radix-icons:dot-filled"
                  width="10"
                  color="white"
                />
                <div className="text-justify">
                be shared with any third parties
                </div>
              </div>
            </div>
            <p onClick={() => handleCardClick(2)} className="shareDataUploadBtn text-[16px] text-white bg-[#609da1] cursor-pointer font-roboto self-end border border-[#609da1] px-5 rounded absolute right-5 bottom-2.5">
              Connect
            </p>
          </div>
        </Card> */}

      </div>
      <div className={`flex justify-end mr-6 ${isPremium && "float-right"}`}>
        {/* {!isPremium && (
          <button className="next_btn" onClick={() => setActiveTab(0)}>
            <span className="next_btn_text">Back</span>
          </button>
        )} */}
        <button className="next_btn" onClick={() => onNextClick()}>
          <span className="next_btn_text">Next</span>
        </button>
      </div>
    </>
  );
};

export default ShareData;
