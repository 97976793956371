import React from "react";
import { Table, Tag } from "antd";
import upLogo from "../../assets/images/upLogo.png";
import downLogo from "../../assets/images/downLogo.png";
const ExtraInfoTable = ({ source, tagsLoading, bestActionLoading }) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Churn Score",
      dataIndex: "churnScore",
      key: "churnScore",
      render: (_, { churnScore }) => (
        <div className="flex justify-center items-center">
          <div>{churnScore}</div>
          <img src={upLogo} alt="up" className="w-5" />
        </div>
      ),
    },
    {
      title: `${source !== "activeComp3" ? "Signal: " : ""}Engagement Score`,
      dataIndex: "engScore",
      key: "engScore",
      render: (_, { engScore }) => (
        <div className="flex justify-center items-center">
          <div>{engScore}</div>
          <img src={downLogo} alt="down" className="w-5" />
        </div>
      ),
    },
    {
      title: `${source !== "activeComp3" ? "Signal: " : ""}Account Fit Score`,
      key: "fitScore",
      dataIndex: "fitScore",
      render: (_, { tagColor, fitScore }) => (
        <div className="flex justify-center">
          <Tag color={tagColor[0]} key={fitScore}>
            {fitScore}
          </Tag>
        </div>
      ),
    },
    {
      title: `${
        source !== "activeComp3" ? "Signal: " : ""
      }Regulatory Indicators`,
      key: "regIndicator",
      dataIndex: "regIndicator",
      render: (_, { tagColor, regIndicator }) => (
        <div className="flex justify-center">
          <Tag color={tagColor[1]} key={regIndicator}>
            {regIndicator}
          </Tag>
        </div>
      ),
    },
    {
      title: `${source !== "activeComp3" ? "Signal: " : ""}Press Releases`,
      key: "press",
      dataIndex: "press",
      render: (_, { tagColor, press }) => (
        <div className="flex justify-center">
          <Tag color={tagColor[2]} key={press}>
            {press}
          </Tag>
        </div>
      ),
    },
    {
      title: `${source !== "activeComp3" ? "Signal: " : ""}M&A News`,
      key: "maNews",
      dataIndex: "maNews",
      render: (_, { tagColor, maNews }) => (
        <div className="flex justify-center">
          <Tag color={tagColor[3]} key={maNews}>
            {maNews}
          </Tag>
        </div>
      ),
    },
    {
      title: `${source !== "activeComp3" ? "Signal: " : ""}Champion Left`,
      key: "champion",
      dataIndex: "champion",
      render: (_, { tagColor, champion }) => (
        <div className="flex justify-center">
          <Tag color={tagColor[4]} key={champion}>
            {champion}
          </Tag>
        </div>
      ),
    },
    {
      title: `${source !== "activeComp3" ? "Signal: " : ""}Headcount Trend`,
      key: "headCount",
      dataIndex: "headCount",
      render: (_, { tagColor, headCount }) => (
        <div className="flex justify-center">
          <Tag color={tagColor[5]} key={headCount}>
            {headCount}
          </Tag>
        </div>
      ),
    },
  ];

  if (source === "activeComp3" && !tagsLoading) {
    const engScoreIndex = columns.findIndex(
      (column) => column.key === "engScore"
    );
    columns.splice(engScoreIndex + 1, 0, {
      title: "Tags",
      key: "tag",
      dataIndex: "tag",
      render: (_, { tag }) => (
        <div className="flex justify-center">
          <Tag color={"#E07E65"} key={tag} className="text-[10px]">
            {tag}
          </Tag>
        </div>
      ),
    });
  }
  if (source === "activeComp3" && !tagsLoading && !bestActionLoading) {
    const tagIndex = columns.findIndex((column) => column.key === "tag");
    columns.splice(tagIndex + 1, 0, {
      title: "Next Best Action",
      key: "bestAction",
      dataIndex: "bestAction",
      render: (_, { bestAction }) => (
        <div className="flex flex-col items-start text-[10px]">
          {bestAction.split("\n").map((action, index) => (
            <div key={index}>{action}</div>
          ))}
        </div>
      ),
    });
  }
  const data = [
    {
      key: "1",
      name: "Infosys",
      churnScore: 70,
      engScore: 20,
      fitScore: 80,
      regIndicator: "Neutral",
      press: "Neutral",
      maNews: "Neutral",
      champion: "True",
      headCount: "Negative",
      tagColor: ["green", "yellow", "yellow", "yellow", "red", "red"],
      tag: "Champion Left",
      bestAction:
        "Track Champion and\nsend new lead to sales.\nFind replacement\nfor former Champion\nand send a welcome email",
    },
    {
      key: "2",
      name: "HTS",
      churnScore: 65,
      engScore: 50,
      fitScore: 90,
      regIndicator: "Negative",
      press: "Negative",
      maNews: "Neutral",
      champion: "False",
      headCount: "Negative",
      tagColor: ["green", "red", "red", "yellow", "green", "red"],
      tag: "Regulatory",
      bestAction:
        "Send summary of\nregulatory changes\nand how they can be\nmitigated",
    },
    {
      key: "3",
      name: "Fujitsu",
      churnScore: 80,
      engScore: 35,
      fitScore: 50,
      regIndicator: "Negative",
      press: "Negative",
      maNews: "Neutral",
      champion: "True",
      headCount: "Neutral",
      tagColor: ["yellow", "red", "red", "yellow", "red", "yellow"],
      tag: "Regulatory",
      bestAction:
        "Send summary of\nregulatory changes\nand how they can be\nmitigated",
    },
    {
      key: "4",
      name: "Panasonic",
      churnScore: 75,
      engScore: 45,
      fitScore: 65,
      regIndicator: "Negative",
      press: "Negative",
      maNews: "Neutral",
      champion: "False",
      headCount: "Negative",
      tagColor: ["yellow", "red", "red", "yellow", "green", "red"],
      tag: "Regulatory",
      bestAction:
        "Send summary of\nregulatory changes\nand how they can be\nmitigated",
    },
    {
      key: "5",
      name: "Blackfriars",
      churnScore: 60,
      engScore: 55,
      fitScore: 90,
      regIndicator: "Neutral",
      press: "Neutral",
      maNews: "Negative",
      champion: "False",
      headCount: "Negative",
      tagColor: ["green", "yellow", "yellow", "red", "green", "red"],
      tag: "M&A",
      bestAction:
        "Find relevant contacts\nat Acquiring company\nand send welcome emails.\nTrack any\ncustomer job changes",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      bordered
      className={`extraTable my-6 w-[90%] ${!tagsLoading && !bestActionLoading && 'px-2'}`}
    />
  );
};
export default ExtraInfoTable;
