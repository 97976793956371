import { Client } from './Client.js'

export const getLeads = async (formdata) => {
  try {
    return await Client.post('/freemium/getLeads', formdata);
  } catch(error){
    return error;
  }
}

export const savefICP = async (formdata) => {
  try {
    return await Client.post('/freemium/saveficp', formdata);
  } catch(error){
    return error;
  }
}

export const shareCSVData = async (formdata) => {
  try {
    return await Client.post('/freemium/shareCSVData', formdata);
  } catch(error){
    return error;
  }
}

export const getAuditReport = async (formdata) => {
  try {
    return await Client.post('/freemium/getAuditReport', formdata);
  } catch(error){
    return error;
  }
}
export const getFileStatus = async (formdata) => {
  try {
    return await Client.post('/freemium/getFileStatus', formdata);
  } catch(error){
    return error;
  }
}
