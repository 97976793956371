import React, { useState } from "react";
import { Input, message, Typography, Spin, Tooltip } from "antd";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { saveUTM } from '../../services/utmServices.js';
import { QuestionCircleFilled } from "@ant-design/icons";
import gtmCopilotName from "../../assets/images/gtmCopilotname.svg";

const { Paragraph } = Typography;

const dummyPrompts = {
  Source: ["google", "facebook", "twitter", "linkedin", "newsletter"],
  Medium: ["cpc", "email", "social", "referral", "banner"],
  Campaign: [
    "spring_sale",
    "product_launch",
    "black_friday",
    "summer_promo",
    "new_feature",
  ],
};

const UTMComponent = () => {
  const [UTM, setUTM] = useState({
    BaseURL: "",
    Source: "",
    Medium: "",
    Campaign: "",
  });
  const [UTMLink, setUTMLink] = useState("");
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const handleClick = async () => {
    if (!UTM.Campaign || !UTM.Source || !UTM.Medium || !UTM.BaseURL) {
      return;
    }
    let linkPattern = /^(http|https):\/\/.+?\..+$/i;
    if (!linkPattern.test(UTM.BaseURL)) {
      message.error("Please enter a valid Base URL");
      return;
    }
    setLoading(true)
    const response = await saveUTM(UTM)
    if (response?.data) {
      if (response?.status === 201) {
        message.error(response?.data)
      } else if (response?.status === 200) {
        console.log(response.data)
        setUTMLink(
          UTM.BaseURL +
            "?utm_source=" +
            UTM.Source +
            "|utm_medium=" +
            UTM.Medium +
            "|utm_campaign=" +
            UTM.Campaign
        );
      }
    }
    setLoading(false)
  };

  const copyScript = () => {
    let textToCopy = UTMLink;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    message.success("UTM Link Copied Successfully");
  };

  const tooltipTitle = (
    <div className="flex flex-col justify-center items-center">
      <div>Please provide a valid URL</div>
      <div className="mt-2">https://www.google.com ✅</div>
      <div>https://www.google.com/ ❌</div>
    </div>
  );

  return (
    <div className="flex h-[90vh] justify-center items-center w-full">
      <div className="absolute top-5 left-8">
        <img src={gtmCopilotName} alt="gtm-logo" width={150} />
      </div>
      {UTMLink ? (
        <div className="flex-col justify-center items-center bg-[#15475b] p-2 rounded-lg">
          <div className="flex flex-col justify-center items-center gap-2 text-white">
            <div>Use the Following Link for</div>
            {Object.keys(UTM).map((item, index) => (
              <div key={index}>
                {item} = <span className="text-[#E07E65]">{UTM[item]}</span>
              </div>
            ))}
          </div>
          <div className="mt-2 relative">
            <CopyOutlined
              className="absolute right-2 top-1 cursor-pointer text-white"
              onClick={copyScript}
            />
            <Paragraph code type="success" className="p-6">
              {UTMLink}
            </Paragraph>
          </div>
          <div className="flex justify-end">
            <span
              className="bg-white p-2 rounded-md cursor-pointer hover:text-white hover:scale-105 duration-300 ease-in-out hover:bg-[#E07E65]"
              onClick={() => navigate("/traffic/admin")}
            >
              View Analytics
            </span>
          </div>
        </div>
      ) : (
        <div className="flex justify-between items-center w-[90%]">
          <div className="absolute right-10 top-10 flex flex-col justify-start items-start gap-2 px-2 py-1 border rounded-md shadow-md">
            <div>Please ensure you have generated the <span className="font-bold">GTMC Tag</span></div>
            <div className="-mt-3">and inserted the same into your index.html file</div>
            <div>If not, you can generate the <span className="font-bold">GTMC Tag</span> <span onClick={() => navigate("/gtmc_tag")} className="text-[#E07E65] cursor-pointer hover:underline">here</span></div>
          </div>
          <div className="absolute flex flex-col justify-center items-center top-[15%] right-1/2 gap-2 border py-6 px-20 rounded-md border-[#609DA1] shadow-2xl bg-[#609DA1]">
            <p className="text-white">Base URL <Tooltip title={tooltipTitle} color="#609DA1" ><QuestionCircleFilled className="absolute text-[12px] ml-1 cursor-pointer"/></Tooltip></p>
            <Input
              className="text-center w-[150%]"
              value={UTM.BaseURL}
              onChange={(e) =>
                setUTM((UTM) => ({ ...UTM, BaseURL: e.target.value }))
              }
            />
          </div>
          {["Source", "Medium", "Campaign"].map((utmLabel, index) => (
            <div
              key={index}
              className="flex flex-col justify-center items-center gap-2 border p-2 rounded-md border-[#E07E65] shadow-2xl bg-[#E07E65] relative"
            >
              <p className="text-white">{utmLabel}</p>
              <Input
                className="text-center"
                value={UTM[utmLabel]}
                onChange={(e) =>
                  setUTM((UTM) => ({ ...UTM, [utmLabel]: e.target.value }))
                }
              />
              <div
                className={`absolute -bottom-44 flex justify-center items-center gap-4 flex-wrap ${
                  index === 2 && "w-[150%]"
                }`}
              >
                {dummyPrompts[utmLabel].map((item, idx) => (
                  <p
                    key={idx}
                    className="border border-[#E07E65] hover:bg-[#E07E65] hover:text-white duration-300 ease-in-out cursor-pointer rounded-full py-2 px-4 text-[#E07E65] text-[12px]"
                    onClick={() =>
                      setUTM((utm) => ({ ...utm, [utmLabel]: item }))
                    }
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          ))}
          {loading ? (
            <Spin indicator={<LoadingOutlined spin />} size="large" className="px-20 border border-white" />
          ) : (
            <div
              className={`py-2 px-4 border border-[#032D3E] ${
                UTM.Source && UTM.Medium && UTM.Campaign && UTM.BaseURL
                  ? "bg-[#032D3E] text-white hover:scale-105 duration-300 ease-in-out hover:shadow-xl cursor-pointer"
                  : "bg-white text-black cursor-not-allowed"
              } rounded-full ml-20`}
              onClick={handleClick}
            >
              Get UTM Link
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UTMComponent;
