import React, { useState, useEffect, useRef } from "react";
import {
  CopyTwoTone,
  ShareAltOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  DatabaseOutlined,
  LinkOutlined,
  AlignLeftOutlined,
} from "@ant-design/icons";
import AudienceTypingEffectUtil from "../SDChat/AudienceTypingEffectUtil";
import { Button, Avatar, Table } from "antd";
import avatar8 from "./../../assets/images/user.png";
import sampleChart1 from "../../assets/images/sampleChart1.png";
import sampleChart2 from "../../assets/images/sampleChart2.png";
import sampleChart3 from "../../assets/images/sampleChart3.png";
import sampleChart4 from "../../assets/images/sampleChart4.png";
import sampleChart5 from "../../assets/images/sampleChart5.png";
import loadingGif from "../../assets/images/powerUserLoading.gif";
import "./ToastChat.css";
import ToastExtraInfo from "./ToastExtraInfo";
import logoWOname from "../../assets/images/logoWOname.png";
import TypingEffect from "../TypingEffect/TypingEffect";

const ToastChat = ({
  chatArray,
  setChatArray,
  setLoading,
  setIsHistoryPromptClicked,
  setQuestion,
  setInputFilled,
  handleSend,
}) => {
  const latestQuestionRef = useRef(null);
  const testRef = useRef(null);
  const chatContentRef = useRef(null);
  const [completed, setCompleted] = useState(false);
  const [chatLoading, setChatLoading] = useState(true);
  const [sentRel1, setSentRel1] = useState(false);
  const [yesSentRel1, setYesSentRel1] = useState(false);
  const [reviews, setReviews] = useState(false);
  const [yesSentRel2, setYesSentRel2] = useState(false);
  const [cta, setCTA] = useState(false);

  useEffect(() => {
    if (chatLoading) {
      setTimeout(() => {
        setChatLoading(false);
      }, 3000);
    }
  }, [chatLoading]);
  const isNumberedList = (text) => {
    // Check if the text starts with a number followed by a dot and a space
    const result1 = /^\d+\.\s/.test(text?.trim());
    const result2 = /^(-|\d+\.\s)/.test(text?.trim());
    // console.log(text, result1);
    return result1 || result2;
  };
  const suggestionButton = (question) => {
    setQuestion(question);
    setInputFilled(true);
    handleSend();
  };
  useEffect(() => {
    if (latestQuestionRef.current) {
      setTimeout(() => {
        latestQuestionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 500);
    }
    setCompleted(false);
  }, [chatArray]);

  useEffect(() => {
    if (testRef && testRef?.current) {
      setTimeout(() => {
        testRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 10);
    }
  }, [sentRel1, yesSentRel1, reviews, yesSentRel2, cta]);
  function renderLink(text) {
    const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let match;
    let lastIndex = 0;
    const elements = [];

    while ((match = linkRegex.exec(text)) !== null) {
      const linkText = match[1];
      const linkUrl = match[2];

      // Add the text before the link
      elements.push(
        <span key={`text-${lastIndex}`}>
          {text.substring(lastIndex, match.index)}
        </span>
      );

      // Add the link
      elements.push(
        <a
          key={`link-${match.index}`}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#609DA1", textDecoration: "underline" }}
        >
          {linkText}
        </a>
      );

      lastIndex = linkRegex.lastIndex;
    }

    // Add the remaining text after the last link
    if (lastIndex < text.length) {
      elements.push(
        <span key={`text-${lastIndex}`}>
          {text.substring(lastIndex, text.length)}
        </span>
      );
    }

    return <div className="inline">{elements}</div>;
  }
  const handleNoClick = () => {
    setChatArray([
      {
        //   question: question,
        chatBotText:
          "Now, let's examine your service quality. What do customers say about the service provided by your staff?",
        //   answer: ["Analyzing current customers that are at risk of churn"],
        isGraph: false,
        suggestions: [],
        isSuggestions: false,
      },
    ]);
    setChatLoading(true);
    setSentRel1(false);
    setYesSentRel1(false);
    setReviews(false);
    setYesSentRel2(false);
    setCTA(false);
  };
  return (
    <div className="w-[107%] flex flex-col">
      {chatArray?.map((chat, index) => (
        <div key={index}>
          <div className="flex ">
            <div
              className={
                chat.isGraph === true ? "w-[45%] mb-10" : "w-[90%] mb-10"
              }
              key={index}
            >
              {index === 0 ? (
                <div ref={testRef}>
                  <div
                    className="flex gap-2"
                    style={{ alignItems: "center", marginLeft: "-25px" }}
                  >
                    <div
                      style={{ height: "40px", width: "40px" }}
                      className="flex justify-center items-center"
                    >
                      <img src={logoWOname} alt="logoWOname" />
                    </div>
                    <div className="text-3xl ml-1">
                      {chat?.chatBotText?.split(".")[0]}
                    </div>
                  </div>
                  <div className="text-2xl ml-7 mt-4">
                    {chat?.chatBotText?.split(".")[1]}
                  </div>
                  {chatLoading ? (
                    <div className="flex items-center ml-0 mt-[0px]">
                      <img src={loadingGif} alt="chat-loading" />
                    </div>
                  ) : (
                    <div className="text-xl ml-7 mt-4">
                      {!sentRel1 ? (
                        <TypingEffect
                          html={
                            chat?.chatBotText
                              ?.trim()
                              ?.toLowerCase()
                              ?.includes(
                                "let's start with a sentiment comparison"
                              )
                              ? "Your restaurant has an average rating of 4.2 stars, which is slightly lower than the top 5 restaurants, which average 4.5 stars."
                              : "Service quality is rated at 4.0 stars on average. Common positive feedback includes friendly and prompt service, while negative feedback often mentions long wait times."
                          }
                          speed={0.5}
                          onTypingComplete={() => setSentRel1(true)}
                        />
                      ) : (
                        <>
                          {chat?.chatBotText
                            ?.trim()
                            ?.toLowerCase()
                            ?.includes(
                              "let's start with a sentiment comparison"
                            )
                            ? "Your restaurant has an average rating of 4.2 stars, which is slightly lower than the top 5 restaurants, which average 4.5 stars."
                            : "Service quality is rated at 4.0 stars on average. Common positive feedback includes friendly and prompt service, while negative feedback often mentions long wait times."}
                        </>
                      )}
                    </div>
                  )}
                  {sentRel1 && (
                    <div className="flex flex-col gap-2 mt-6">
                      <div className="ml-7 text-lg">
                        {chat?.chatBotText
                          ?.trim()
                          ?.toLowerCase()
                          ?.includes("let's start with a sentiment comparison")
                          ? "Would you like to see a breakdown of positive, negative, and neutral reviews for your restaurant?"
                          : "Would you like to see specific keywords frequently mentioned in service-related reviews?"}
                      </div>
                      <div className="flex items-center gap-4 ml-7">
                        <div
                          className={`py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-pointer leading-[1rem] w-fit rounded-md hover:bg-[#609DA1] hover:text-white`}
                          onClick={() => setYesSentRel1(true)}
                          // ref={customerRef}
                        >
                          {chat?.chatBotText
                            ?.trim()
                            ?.toLowerCase()
                            ?.includes(
                              "let's start with a sentiment comparison"
                            )
                            ? "Yes, show me the breakdown."
                            : "Yes, show me the keywords."}
                        </div>
                        <div
                          className="py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-pointer leading-[1rem] w-fit rounded-md hover:bg-[#609DA1] hover:text-white"
                          onClick={chat?.chatBotText
                            ?.trim()
                            ?.toLowerCase()
                            ?.includes(
                              "let's start with a sentiment comparison"
                            ) ? handleNoClick : null}
                        >
                          No, proceed to the next analysis.
                        </div>
                      </div>
                    </div>
                  )}
                  {yesSentRel1 && (
                    <>
                      <div className="ml-7 text-xl mt-6">
                        {!reviews ? (
                          <TypingEffect
                            html={
                              chat?.chatBotText
                                ?.trim()
                                ?.toLowerCase()
                                ?.includes(
                                  "let's start with a sentiment comparison"
                                )
                                ? "Here's the breakdown:"
                                : "Common keywords include:"
                            }
                            speed={0.5}
                            onTypingComplete={() => setReviews(true)}
                          />
                        ) : (
                          <>
                            {chat?.chatBotText
                              ?.trim()
                              ?.toLowerCase()
                              ?.includes(
                                "let's start with a sentiment comparison"
                              )
                              ? "Here's the breakdown:"
                              : "Common keywords include:"}
                          </>
                        )}
                      </div>
                      {reviews && (
                        <>
                          <div className="flex flex-col ml-48 mt-4 gap-2 text-lg">
                            {chat?.chatBotText
                              ?.trim()
                              ?.toLowerCase()
                              ?.includes(
                                "let's start with a sentiment comparison"
                              ) ? (
                              <>
                                <div>
                                  <span className="font-bold">65%</span>{" "}
                                  positive
                                </div>
                                <div>
                                  <span className="font-bold">20%</span> neutral
                                </div>
                                <div>
                                  <span className="font-bold">15%</span>{" "}
                                  negative
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <span className="font-bold">friendly</span>{" "}
                                  (45 mentions)
                                </div>
                                <div>
                                  <span className="font-bold">attentive</span>{" "}
                                  (30 mentions)
                                </div>
                                <div>
                                  <span className="font-bold">slow</span> (20
                                  mentions)
                                </div>
                              </>
                            )}
                          </div>
                          <div className="ml-7 text-lg mt-6">
                            {chat?.chatBotText
                              ?.trim()
                              ?.toLowerCase()
                              ?.includes(
                                "let's start with a sentiment comparison"
                              )
                              ? "Are you interested in seeing how the sentiment has changed over time?"
                              : "Do you want to see a comparison of service quality ratings with the top 5 restaurants?"}
                          </div>
                          <div className="flex items-center gap-4 ml-7 mt-2">
                            <div
                              className={`py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-pointer leading-[1rem] w-fit rounded-md hover:bg-[#609DA1] hover:text-white`}
                              onClick={() => setYesSentRel2(true)}
                              // ref={customerRef}
                            >
                              {chat?.chatBotText
                                ?.trim()
                                ?.toLowerCase()
                                ?.includes(
                                  "let's start with a sentiment comparison"
                                )
                                ? "Yes, show me the trend over time."
                                : "Yes, show the comparison."}
                            </div>
                            <div
                              className="py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-pointer leading-[1rem] w-fit rounded-md hover:bg-[#609DA1] hover:text-white"
                              onClick={chat?.chatBotText
                                ?.trim()
                                ?.toLowerCase()
                                ?.includes(
                                  "let's start with a sentiment comparison"
                                ) ? handleNoClick : null}
                            >
                              No, proceed to the next section.
                            </div>
                          </div>
                          {yesSentRel2 && (
                            <>
                              <div className="ml-7 text-xl mt-6">
                                {!cta ? (
                                  <TypingEffect
                                    html={
                                      chat?.chatBotText
                                        ?.trim()
                                        ?.toLowerCase()
                                        ?.includes(
                                          "let's start with a sentiment comparison"
                                        )
                                        ? "Over the past six months, positive reviews have increased by 10%, while negative reviews have decreased by 5%."
                                        : "Your service quality rating is 4.0 stars, compared to the top 5 restaurants' average of 4.3 stars."
                                    }
                                    speed={0.5}
                                    onTypingComplete={() => setCTA(true)}
                                  />
                                ) : chat?.chatBotText
                                    ?.trim()
                                    ?.toLowerCase()
                                    ?.includes(
                                      "let's start with a sentiment comparison"
                                    ) ? (
                                  <>
                                    Over the past six months, positive reviews
                                    have increased by{" "}
                                    <span className="font-bold">10%</span>,
                                    while negative reviews have decreased by{" "}
                                    <span className="font-bold">5%</span>.
                                  </>
                                ) : (
                                  <>
                                    Your service quality rating is{" "}
                                    <span className="font-bold">4.0 stars</span>
                                    , compared to the top 5 restaurants' average
                                    of{" "}
                                    <span className="font-bold">
                                      4.3 stars.
                                    </span>
                                    .
                                  </>
                                )}
                              </div>
                              {cta && (
                                <div className="flex flex-col gap-2 mt-6 ml-7 text-xl">
                                  <TypingEffect
                                    html={"Here is my Recommendation:"}
                                    speed={0.5}
                                    onTypingComplete={() => console.log("done")}
                                  />
                                  <div className="font-bold">
                                    <TypingEffect
                                      html={
                                        chat?.chatBotText
                                          ?.trim()
                                          ?.toLowerCase()
                                          ?.includes(
                                            "let's start with a sentiment comparison"
                                          )
                                          ? "Consider promoting recent positive feedback in your marketing materials to highlight the improvements."
                                          : "Improving wait times and continuing to emphasize friendly service could help close the gap with top competitors."
                                      }
                                      speed={0.5}
                                      onTypingComplete={() =>
                                        console.log("done")
                                      }
                                    />
                                  </div>
                                  <div
                                    className="py-2 px-1 border border-[#609DA1] text-[#609DA1] cursor-pointer leading-[1rem] w-fit rounded-md hover:bg-[#609DA1] hover:text-white text-base mb-10"
                                    onClick={
                                      chat?.chatBotText
                                        ?.trim()
                                        ?.toLowerCase()
                                        ?.includes(
                                          "let's start with a sentiment comparison"
                                        )
                                        ? handleNoClick
                                        : null
                                    }
                                  >
                                    Proceed to the next section.
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div
                    className="flex gap-2 mb-1 mt-2"
                    style={{ alignItems: "center", marginLeft: "-30px" }}
                  >
                    <div
                      style={{ height: "40px", width: "40px" }}
                      className="flex justify-center items-center"
                    >
                      <Avatar
                        src={
                          localStorage.getItem("profile_url")
                            ? localStorage.getItem("profile_url").toString()
                            : avatar8
                        }
                      ></Avatar>
                    </div>
                    <div className="text-2xl ml-2">{chat?.question}</div>
                  </div>

                  <div className="flex items-center gap-2.5 mt-6 mb-2">
                    <AlignLeftOutlined style={{ fontSize: "24px" }} />
                    <div
                      ref={
                        index === chatArray.length - 1
                          ? latestQuestionRef
                          : null
                      }
                      className="flex justify-center items-center text-2xl pb-0.5"
                    >
                      Answer
                    </div>
                  </div>
                </>
              )}
              {(index !== chatArray?.length - 1 || completed) &&
                chat?.answer?.map((ans, ansIndex) => (
                  <div
                    key={`ans_${ansIndex}`}
                    style={{
                      paddingLeft: isNumberedList(ans) ? "70px" : "2.5rem",
                      fontFamily: "open sans, Helvetica, Arial, sans-serif",
                    }}
                    className={` ${
                      isNumberedList(ans) && "my-2"
                    } text-justify text-[1.125rem] leading-[1.75rem]`}
                  >
                    {isNumberedList(ans) ? (
                      <div
                        className={`flex gap-${
                          Number(ans?.split(" ")[0]?.split(".")[0]) < 10
                            ? "5"
                            : "3"
                        }`}
                      >
                        <div className="text-end">
                          {ans?.split(" ")[0]?.trim()}
                        </div>
                        <div>
                          {renderLink(
                            ans?.split(" ").slice(1).join(" ").trim()
                          )}
                        </div>
                      </div>
                    ) : (
                      <>{renderLink(ans?.trim())}</>
                      // <>{ans?.trim()}</>
                    )}
                  </div>
                ))}
              {index === chatArray?.length - 1 && !completed && (
                <>
                  <AudienceTypingEffectUtil
                    chatArray={chatArray}
                    isNumberedList={isNumberedList}
                    renderLink={renderLink}
                    setCompleted={setCompleted}
                    chatContentRef={chatContentRef}
                    setLoading={setLoading}
                  />
                  <div ref={chatContentRef} />
                </>
              )}

              {completed && (
                <>
                  {chat?.question
                    ?.toLowerCase()
                    .trim()
                    .includes("revenue down this week") ? (
                    <img className="mt-4 ml-8" src={sampleChart1} />
                  ) : chat?.question
                      ?.toLowerCase()
                      .trim()
                      .includes("drop in influencer partnership") ? (
                    <img className="mt-4 ml-8" src={sampleChart2} />
                  ) : chat?.question
                      ?.toLowerCase()
                      .trim()
                      .includes("campaign perform last week?") ? (
                    <img className="mt-4 ml-8" src={sampleChart3} />
                  ) : chat?.question
                      ?.toLowerCase()
                      .trim()
                      .includes("active users?") ? (
                    <img className="mt-4 ml-8" src={sampleChart4} />
                  ) : chat?.question
                      ?.toLowerCase()
                      .trim()
                      .includes("sales revenue this week?") ? (
                    <img className="mt-4 ml-8" src={sampleChart5} />
                  ) : (
                    <div style={{ display: "none" }}></div>
                  )}
                  <>
                    {chat?.isSuggestions === true ? (
                      <span className="ml-10 mt-6 color-[gray]">
                        Suggestions:-
                      </span>
                    ) : (
                      ""
                    )}

                    <div className="flex gap-2 ml-10 mt-2">
                      {chat?.suggestions?.map((suggestion, index) => (
                        <Button
                          key={index}
                          onClick={() => suggestionButton(suggestion)}
                        >
                          {suggestion}
                        </Button>
                      ))}
                    </div>
                  </>
                </>
              )}
            </div>
            {chat.isGraph === true && (
              <div
                className="flex justify-center w-[45%] mt-10 ml-2"
                style={{ height: "fit-content" }}
              >
                {chat?.question
                  .toLowerCase()
                  .trim()
                  .includes("revenue down this week") ? (
                  // <img style={{height: "fit-content"}} className="mt-4 ml-8" src={sampleChart1} />
                  <Table
                    className="demoAppTable"
                    columns={[
                      { title: "Date", dataIndex: "date", key: "date" },
                      {
                        title: "Revenue",
                        dataIndex: "revenue",
                        key: "revenue",
                      },
                    ]}
                    dataSource={[
                      { date: "December'29", revenue: 7800 },
                      { date: "January'05", revenue: 15500 },
                      { date: "January'12", revenue: 9000 },
                      { date: "January'19", revenue: 18000 },
                      { date: "January'26", revenue: 11000 },
                      { date: "February'03", revenue: 28000 },
                    ]}
                    pagination={false} // If you want pagination, remove this line
                  />
                ) : chat?.question
                    .toLowerCase()
                    .trim()
                    .includes("drop in influencer partnership") ? (
                  // <img style={{height: "fit-content"}} className="mt-4 ml-8" src={sampleChart2} />
                  <Table
                    className="demoAppTable"
                    columns={[
                      { title: "Social Media", dataIndex: "sm", key: "sm" },
                      { title: "User Count", dataIndex: "uc", key: "uc" },
                    ]}
                    dataSource={[
                      { sm: "Facebook", uc: 730000 },
                      { sm: "Linkedin", uc: 65000 },
                      { sm: "Twitter", uc: 4000 },
                      { sm: "Instagram", uc: 1000 },
                    ]}
                    pagination={false} // If you want pagination, remove this line
                  />
                ) : // chat?.question.toLowerCase().trim().includes("campaign perform last week?") ?
                // <img style={{height: "fit-content"}} className="mt-4 ml-8" src={sampleChart3} /> :
                // chat?.question.toLowerCase().trim().includes("active users?") ?
                // <img style={{height: "fit-content"}} className="mt-4 ml-8" src={sampleChart4} /> :
                chat?.question
                    .toLowerCase()
                    .trim()
                    .includes("sales revenue this week?") ? (
                  // <img style={{height: "fit-content"}} className="mt-4 ml-8" src={sampleChart2} />
                  <Table
                    className="w-[60%] demoAppTable"
                    style={{ overflowX: "scroll" }}
                    columns={[
                      { title: "Date", dataIndex: "date", key: "date" },
                      {
                        title: "Paid Ads",
                        dataIndex: "paidAds",
                        key: "paidAds",
                      },
                      {
                        title: "Influencer Partnership",
                        dataIndex: "influencerPartnership",
                        key: "influencerPartnership",
                      },
                      {
                        title: "SmartHome Essentials",
                        dataIndex: "smartHomeEssentials",
                        key: "smartHomeEssentials",
                      },
                      {
                        title: "Tech Gadgets",
                        dataIndex: "techGadgets",
                        key: "techGadgets",
                      },
                      { title: "Others", dataIndex: "others", key: "others" },
                    ]}
                    dataSource={[
                      {
                        date: "December'29",
                        paidAds: 100,
                        influencerPartnership: 75,
                        smartHomeEssentials: 50,
                        techGadgets: 25,
                        others: 15,
                      },
                      {
                        date: "January'15",
                        paidAds: 100,
                        influencerPartnership: 75,
                        smartHomeEssentials: 50,
                        techGadgets: 25,
                        others: 15,
                      },
                      {
                        date: "January'31",
                        paidAds: 100,
                        influencerPartnership: 75,
                        smartHomeEssentials: 50,
                        techGadgets: 25,
                        others: 15,
                      },
                    ]}
                    pagination={false} // If you want pagination, remove this line
                  />
                ) : (
                  <div style={{ display: "none" }}></div>
                )}
              </div>
            )}
          </div>
          <div>
            {(completed || index !== chatArray?.length - 1) && (
              <>
                {chat?.sources &&
                  chat?.sources?.length > 0 &&
                  chat?.sources?.some(Boolean) && (
                    <>
                      <div className="flex items-center gap-2.5 mb-2">
                        <DatabaseOutlined style={{ fontSize: "24px" }} />
                        <div className="flex justify-center items-center text-2xl pb-0.5">
                          Sources
                        </div>
                      </div>
                      <div className="flex flex-wrap pl-[3rem] justify-start items-center gap-5 pb-2 w-[90%]">
                        {chat?.sources?.map(
                          (source, sourceIdx) =>
                            source !== false &&
                            source?.title && (
                              <div
                                key={`source-${sourceIdx}`}
                                className="flex flex-col justify-center items-center border rounded-lg w-[300px] h-[100px] gap-5 cursor-pointer border-[#609DA1] bg-gray-100 hover:scale-105 delay-150 duration-300 ease-in-out"
                                onClick={() =>
                                  window.open(
                                    `${source?.url}`,
                                    "_blank",
                                    "noreferrer"
                                  )
                                }
                              >
                                <div className="flex justify-start items-center w-[90%] text-lg">
                                  {source?.title?.length > 30
                                    ? source?.title?.substring(0, 30) + "..."
                                    : source?.title}
                                </div>
                                <div className="flex justify-start items-center gap-2 w-full pl-[0.75rem]">
                                  <div className="flex justify-center items-center border border-[#609DA1] rounded-full p-2 bg-white">
                                    {source?.image?.includes("http") ? (
                                      <img
                                        alt="img"
                                        src={source?.image}
                                        width={20}
                                      />
                                    ) : (
                                      <LinkOutlined
                                        style={{
                                          color: "black",
                                          fontSize: "20px",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="text-[12px]">
                                    {source?.siteName || "Source"}
                                  </div>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </>
                  )}
                {chatLoading ? (
                  <div className="flex items-center ml-32 mt-[-50px]">
                    <img src={loadingGif} alt="chat-loading" />
                  </div>
                ) : chat?.question
                    ?.toLowerCase()
                    ?.trim()
                    ?.includes("you tell me") ? (
                  <ToastExtraInfo setChatArray={setChatArray} />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToastChat;
