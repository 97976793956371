import React, { useState, useEffect } from "react";
import { Input, Switch, message, Spin, Button, Menu, Dropdown } from "antd";
import {
  ForkOutlined,
  ApiOutlined,
  PaperClipOutlined,
  ArrowRightOutlined,
  DatabaseOutlined,
  BarChartOutlined,
  TeamOutlined,
  BuildOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import loadingGif from "../../assets/images/powerUserLoading.gif";
import "./SDInitial.css";

const { TextArea } = Input;

const SDInitial = ({
  setInitialCalled,
  setQuestion,
  question,
  setAnswer,
  loading,
  setLoading,
  attachedFile,
  setAttachedFile,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [rows, setRows] = useState(1);
  const [divFocused, setDivFocused] = useState(true);
  const [inputFilled, setInputFilled] = useState(false);
  const [isPromptClicked, setIsPromptClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Hybrid");

  const handlePromptClick = (prompt) => {
    setQuestion(prompt);
    setInputFilled(true);
    setIsPromptClicked(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (!value) {
      setInputFilled(false);
    } else {
      setInputFilled(true);
    }
    const lines = value.split("\n").length;
    const newRows = Math.min(lines, 5);
    setRows(newRows || 1);
    setQuestion(value);
  };

  const handleTextAreaFocus = () => {
    setDivFocused(true);
  };

  const handleTextAreaBlur = () => {
    setDivFocused(false);
  };

  const handleSend = async () => {
    if (inputFilled) {
      // setLoading(true);
      setAnswer("Analyzing current customers that are at risk of churn");
      setInitialCalled(false);
    }
    setIsPromptClicked(false);
  };
  useEffect(() => {
    if (attachedFile?.name) {
      handleSend();
    }
  }, [attachedFile]);
  useEffect(() => {
    if (isPromptClicked === true) {
      handleSend();
    }
  }, [isPromptClicked]);
  if (loading) {
    return (
      <div
        className="h-full flex justify-center"
        style={{ alignItems: "center" }}
      >
        <img src={loadingGif} alt="loadingGif" className="h-[150px]" />
        {/* <Loading /> */}
      </div>
    );
  }

  return (
    <div className="flex justify-center mt-[100px]">
      <div className="w-[650px] flex flex-col justify-center items-center h-full">
        <div
          className="w-[90%] text-[200%] justify-center text-center items-center mb-2 font-bold"
          style={{ position: "relative" }}
        >
          Hi this is ADA, your personal GTM CoPilot. How can I help you today?
        </div>
        <TextArea
          autoSize={{ minRows: 1, maxRows: 5 }}
          rows={rows}
          value={question}
          onChange={handleInputChange}
          onFocus={handleTextAreaFocus}
          onBlur={handleTextAreaBlur}
          placeholder="Ask anything..."
          className={`audResearchTextArea text-xl py-4`}
          autoFocus
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <div
          className={`w-full flex justify-end items-center p-4 pb-3 audResearchTextBottom ${
            divFocused && "divFocused"
          }`}
        >
          <div className="flex justify-center items-center gap-5">
            <div
              className={`flex justify-center items-center gap-2 border-2 rounded-2xl p-2 ${
                inputFilled
                  ? "bg-[#609DA1] cursor-pointer"
                  : "cursor-not-allowed"
              }`}
              onClick={handleSend}
            >
              <ArrowRightOutlined
                style={{ color: inputFilled ? "white" : "black" }}
              />
            </div>
          </div>
        </div>
        {attachedFile && (
          <div className="mt-2 text-gray">
            Attached File: {attachedFile.name}
          </div>
        )}
        <div>
          {/* <div className="flex flex-wrap justify-center mt-4"> */}
          <div className="prompt-container item-center">
            <div
              className="text-left flex py-2 px-4 gap-5 text-gray mt-2"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div
                onClick={() =>
                  handlePromptClick(
                    "Which customers are at a high risk of churn?"
                  )
                }
                className={`flex text-[#609DA1] hover:bg-[#609DA1] hover:text-white ${
                  selectedButton === "Search" ? "selectedButton" : ""
                }`}
                style={{
                  border: "1px solid #609DA1",
                  borderRadius: "6px",
                  padding: "0px 5px",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                Analyze Customers
              </div>
              <div
                className={`flex text-[#609DA1] ${
                  selectedButton === "Report" ? "selectedButton" : ""
                }`}
                style={{
                  border: "1px solid #609DA1",
                  borderRadius: "6px",
                  padding: "0px 5px",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                Discover Accounts
              </div>
              <div
                className={`flex text-[#609DA1] ${
                  selectedButton === "Segment" ? "selectedButton" : ""
                }`}
                style={{
                  border: "1px solid #609DA1",
                  borderRadius: "6px",
                  padding: "0px 5px",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                Discover Leads
              </div>
              <div
                className={`flex text-[#609DA1] ${
                  selectedButton === "DataOps" ? "selectedButton" : ""
                }`}
                style={{
                  border: "1px solid #609DA1",
                  borderRadius: "6px",
                  padding: "0px 5px",
                  alignItems: "center",
                  cursor: "not-allowed"
                }}
              >
                See Lead Trends
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default SDInitial;
