import { useState, useEffect } from "react";
import { Row } from "antd";

function Header_v2({ }) {

  return (
    <>
      <Row gutter={[24, 0]}>

      </Row>
    </>
  );
}

export default Header_v2;
