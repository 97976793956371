import React, { useState, useEffect } from "react";
import ExtraInfoTable from "../../components/SDChat/ExtraInfoTable";
import loadingGif from "../../assets/images/powerUserLoading.gif";

import { schneiderFetch } from "../../services/SchneiderServices";

const ActiveComp3 = ({ tagsLoading, setTagsLoading, bestActionLoading, setBestActionLoading }) => {
    const [slackLoading, setSlackLoading] = useState(false)

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setTagsLoading(false)
            setBestActionLoading(true)
        }, 3000)

        return () => clearTimeout(timeoutId)
    }, [])

    useEffect(() => {
        if (bestActionLoading) {
            let timeoutId = setTimeout(() => {
                setBestActionLoading(false)
                setSlackLoading(true)
            }, 3000)
    
            return () => clearTimeout(timeoutId)
        }
    }, [bestActionLoading])

    const handleMessageSend = async () => {
      const fetchingData = {
          channel: "D06SFUAF6KZ",
          text: "newdavidmessage"
      }
      const response = await schneiderFetch(fetchingData)
      console.log(response)
    }

    useEffect(() => {
        if (slackLoading) {
            let timeoutId = setTimeout(() => {
                setSlackLoading(false)
                handleMessageSend()
            }, 3000)
    
            return () => clearTimeout(timeoutId)
        }
    }, [slackLoading])
  // ${!tagsLoading && !bestActionLoading ? 'items-start gap-1' : 'items-center gap-10'}
  return (
    <div className={`flex flex-col items-center gap-10 justify-start h-full`}>
      <div className="flex items-center justify-center w-full">
        {(tagsLoading || bestActionLoading || slackLoading) && (
          <>
            <img src={loadingGif} alt="loading" width={70} />
            {tagsLoading ? (
                <div>Adding Tags</div>
            )  : bestActionLoading ? (
                <div>Adding Next Best Action</div>
            ) : (
                <div>Sending Test Slack to Workflow Agent Owner</div>
            )}
          </>
        )}
      </div>
      <ExtraInfoTable source={"activeComp3"} tagsLoading={tagsLoading} bestActionLoading={bestActionLoading} />
    </div>
  );
};

export default ActiveComp3;
