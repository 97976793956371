import React, { useState, useEffect, useRef } from "react";

import { PlusCircleOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Switch, Input } from "antd";
import loadingGif from '../../assets/images/loaderNew.gif';
import LoaderWithText from "../LoaderWithText/LoaderWithText";

import "./HomeLater_v2.css";
import { amariAPI } from "../../services/amariServices";
import { set } from "react-ga";
import HomeResChat from "../HomeResChat/HomeResChat";

import { ragResponse } from "../../services/FileUploadServices";

const { TextArea } = Input;
const HomeLater_v2 = ({ attachedFile, question, answer, setQuestion, loading, setLoading, setAttachedFile }) => {
  const [rows, setRows] = useState(1);  
  const containerRef = useRef(null);
  const [inputFilled, setInputFilled] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [chatArray, setChatArray] = useState([]);
  const staticAnswers = {
    "first": "Sales revenue is down 15% to $9.7M, which is a drop of $1.8M compared to last week.\nThis decrease is due to the following five key factors:\n1. Users from the Paid Ads had a 2% drop from 150k users to 147.2k users, which decreased revenue by $530k\n2. Users from the Influencer Partnerships campaign source were down 1% from 215k to 212k users, which decreased revenue by $800k\n3. Revenue from Product category: SmartHome Essentials was down 54%, which is a decrease of $920k, while daily active users remained the same\n4. Revenue from Product category: Tech Gadgets was down 36%, which is a decrease of $560k, while daily active users remained the same\n5. Users from the ConnectAndSave campaign were down 1% from 236k to 233k users, which decreased revenue by $1.3M",
    "second": "Sure thing. The drop in Influencer Partnerships was primarily driven by users from Facebook, dropping 18% from 14k to 11k users. This decreased the revenue generated by $730k, dropping to $2.7M compared to $3.4M the previous week.",
    "third": "The Q1_SmartHome Campaign performed well across three out of four platforms but showed the best performance on Linkedin. Conversion rate (free to paid users) from the campaign:\n1. 21% decrease from Facebook, driving 5% fewer paid users\n2. 106% increase from Linkedin, driving 0.5% more paid users\n3. 5% increase from Twitter driving 1% more paid users\n4. 10% increase from Instagram driving 1.5% more paid users",
    "fourth": "Daily active users were up from the campaign across all platforms as well\n1. 13% decrease from Linkedin, driving 57 less daily active users\n2. 11% increase from FB driving 4 more daily active users\n3. 2% increase from Twitter driving 20 more daily active users\n4. 3% increase from Instagram driving 25 more daily active users",
    "fifth": "Although the campaign showed an overall positive performance, the campaign had a positive but minimal impact on Sales Revenue this week. We can see the total Sales Revenue attributable to the Q1_SmartHome marketing campaign was about $25k. There were considerably larger factors impacting the 15% decrease in Sales Revenue this week:\n1. Sales Revenue from Paid Ads was down 10%, a decrease of $530k\n2. Sales Revenue from Influencer Partnerships was down 19%, a decrease of $800k\n3. Sales Revenue from Product category: SmartHome Essentials was down 54%, a decrease of $920k\n4. Sales Revenue from Product category: Tech Gadgets was down 36%, a decrease of $560k\n5. Sales Revenue from ConnectAndSave campaign was down 14%, a decrease of $1.3M"
  }
  const handleInputChange = (e) => {
    if (e.target.value.trim() === "") {
      setInputFilled(false);
    } else {
      setInputFilled(true);
    }
    const lines = e.target.value.split("\n").length;
    const newRows = Math.min(lines, 5);
    setRows(newRows || 1);
    setQuestion(e.target.value);
  };
  useEffect(() => {
    if (!loading && containerRef.current) {
      // Scroll to the bottom of the container
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [loading]);
  const username = JSON.parse(localStorage.getItem("user"))?.name?.replaceAll("|", " ")
  const talkToData = async () => { // RAG API call
    if (chatArray.length === 0) {
      setChatArray([
        {question, suggestions: []
        }
      ])
    } else {
      setChatArray(prevChatArray => [
        ...prevChatArray,
        {question, suggestions: []}
      ])
    }
    const payload = {
      session_id: localStorage.getItem("ragSessionID"),
      user_id: username,
      question
    };
    console.log(payload)
    try {
      let response = await ragResponse(payload)
      const res = response?.data?.responseData
      console.log(res)
      if (res["Status"] === "PASS") {
        setChatArray(prevChatArray => {
          const lastIndex = prevChatArray.length - 1
          return [
            ...prevChatArray.slice(0, lastIndex),
            {
              ...prevChatArray[lastIndex],
              answer: res?.result?.result?.trim()?.split("\n") || ["Cannot process that"],
              isGraph: false,
              suggestions: res?.result["follow-up"] || [],
              isSuggestions: true
            }
          ]
        })
      } else {
        setChatArray(prevChatArray => {
          const lastIndex = prevChatArray.length - 1
          return [
            ...prevChatArray.slice(0, lastIndex),
            {
              ...prevChatArray[lastIndex],
              answer: ["Cannot process that"],
              isGraph: false,
              suggestions: [],
              isSuggestions: false
            }
          ]
        })
      }
    } catch (e) {
      console.error(e)
      setChatArray(prevChatArray => {
        const lastIndex = prevChatArray.length - 1
        return [
          ...prevChatArray.slice(0, lastIndex),
          {
            ...prevChatArray[lastIndex],
            answer: ["Cannot process that"],
            isGraph: false,
            suggestions: [],
            isSuggestions: false
          }
        ]
      })
    }
  }
  useEffect(() => {
    if (attachedFile) {
      talkToData()
    } else if (question.toLowerCase().trim().includes("data ops:")) {
      setChatArray([
        {
          question: question,
          answer: ["Please Attach your CSV File"],
          isGraph: false,
          suggestions: ["Attach"],
          isSuggestions: true
        },
      ]);
    } else if(question == "Reports, Analytics & Recommendations: Create reports, forecasting, campaign analytics"){
      setChatArray([
        {
          question: question,
          answer: ["It looks like you are looking for insights, here are few suggestions for you"],
          isGraph: false,
          suggestions: ["Why is my Revenue down this week?", "How did our latest campaign perform last week?"],
          isSuggestions: true
        },
      ]);
    }
    else if(question.toLowerCase().trim().includes("revenue down this week")){
      setChatArray([
        {
          question: question,
          answer: staticAnswers.first?.split("\n") || ["Cannot Process that"],
          isGraph: true,
          suggestions: ["Tell me more about the drop in Influencer Partnership", "How did our latest campaign perform last week?"],
          isSuggestions: true
        },
      ]);
    }
    else if ( question.toLowerCase().trim().includes("drop in influencer partnership")) {
      setChatArray([
        {
          question: question,
          answer: staticAnswers.second?.split("\n") || ["Cannot Process that"],
          isGraph: true,
          suggestions: ["How did our latest campaign perform last week?", "Effect of last campaign on Active Users?"],
          isSuggestions: true
        },
      ]);
    }
    else if ( question.toLowerCase().trim().includes("campaign perform last week?")){
      setChatArray([
        {
          question: question,
          answer: staticAnswers.third?.split("\n") || ["Cannot Process that"],
          isGraph: true,
          suggestions: ["Effect of last campaign on Active Users?", "Effect of last campaign on Sales Revenue this week?"],
          isSuggestions: true
        },
      ]);
    }
    else if ( question.toLowerCase().trim().includes("active users?")){
      setChatArray([
        {
          question: question,
          answer: staticAnswers.fourth?.split("\n") || ["Cannot Process that"],
          isGraph: true,
          suggestions: ["Effect of last campaign on Sales Revenue this week?"],
          isSuggestions: true
        },
      ]);
    }
    else if ( question.toLowerCase().trim().includes("sales revenue this week")){
      setChatArray([
        {
          question: question,
          answer: staticAnswers.fifth?.split("\n") || ["Cannot Process that"],
          isGraph: true,
        },
      ]);
    } 
    else if(question.includes("You've uploaded")){
      setChatArray([
        {
          question: question,
          answer: "I am analysing the file you have uploaded:\n1. File has 5 columns\n2. company_name column represent name of companies and has no missing values\n3. email column represents emails of individual, it is a PII filed and has 20% missing values\n4. website column represents URL's with 10% missing values\n5. contact_name represents name of individuals with no missing values\n6. company_country represents country with no missing values.\n7. There are 12% duplicate rows in the file".split('\n'),
          isGraph: false,
          suggestions: ["Clean and deduplicate file?", "Clean, deduplicate and check for net new records?", "Ask questions about the file?"],
          isSuggestions: true
        },
      ]);
    }
     else {
      setChatArray([
        {
          question: question,
          answer: answer?.responseData?.split("\n") || ["Cannot Process that"],
          sources: answer?.sources || [],
        },
      ]);
    }
  }, []);
  useEffect(() => {
    if (chatArray?.length === 1) {
      setQuestion("");
    }
  }, [chatArray]);
  const handleSend = async () => {
    if (inputFilled) {
      // if(answer != "I am analysing the file you have uploaded:\n1. File has 5 columns\n2. company_name column represent name of companies and has no missing values\n3. email column represents emails of individual, it is a PII filed and has 20% missing values\n4. website column represents URL's with 10% missing values\n5. contact_name represents name of individuals with no missing values\n6. company_country represents country with no missing values.\n7. There are 12% duplicate rows in the file"){
        setLoading(true);
        if (attachedFile) {
          if (!chatArray[0]?.question?.toLowerCase()?.trim()?.includes("data ops:")) {
            talkToData()
          } else {
            // setLoading(false)
            console.log("rgkerg")
          }
        } else {
          // const payload = {
          //   question: question + ", with sources",
          // };
          // let response = await amariAPI(payload);
          if(question.toLowerCase().trim().includes("revenue down this week")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: staticAnswers.first?.split("\n") || ["Cannot Process that"],
                isGraph: true,
                suggestions: ["Tell me more about the drop in Influencer Partnership", "How did our latest campaign perform last week?"],
                isSuggestions: true
              },
            ]);
          }
          else if ( question.toLowerCase().trim().includes("drop in influencer partnership")) {
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: staticAnswers.second?.split("\n") || ["Cannot Process that"],
                isGraph: true,
                suggestions: ["How can I improve performance?"],
                isSuggestions: true
              },
            ]);
          }
          else if ( question.toLowerCase().trim().includes("how can i improve performance?")) {
            console.log("fkfhqrehk")
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: "Given the nature of your product it makes sense that Facebook is your highest performing channel. I would recommend reallocating influencer budget from LinkedIn and Twitter and distributing that budget in the following manner:\nFacebook: 80%\nInstagram: 20%\nReasoning: LinkedIn and Twitter user personas align more closely with B2B companies, whereas Facebook and Instagram have personas that are more aligned with your company's core demographic. Based on my analysis of your channel spend, very little has been spent on Instagram. Considering Instagram and Facebook are both Meta properties with similar demographics, it is reasonable to deduce that Instagram will perform in a similar manner to Facebook, thus I am recommending the allocation of 20% of the spend on this channel. I have created a forecast with the Facebook and Instagram numbers highlighted in bold based on the expected outcomes with the refactored channel spend.".split("\n"),
                isGraph: false,
                suggestions: ["How did our latest campaign perform last week?", "Effect of last campaign on Active Users?"],
                isSuggestions: true
              },
            ]);
          }
          else if ( question.toLowerCase().trim().includes("campaign perform last week?")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: staticAnswers.third?.split("\n") || ["Cannot Process that"],
                isGraph: true,
                suggestions: ["Effect of last campaign on Active Users?", "Effect of last campaign on Sales Revenue this week?"],
                isSuggestions: true
              },
            ]);
          }
          else if ( question.toLowerCase().trim().includes("active users?")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: staticAnswers.fourth?.split("\n") || ["Cannot Process that"],
                isGraph: true,
                suggestions: ["Effect of last campaign on Sales Revenue this week?"],
                isSuggestions: true
              },
            ]);
          }
          else if ( question.toLowerCase().trim().includes("sales revenue this week?")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: staticAnswers.fifth?.split("\n") || ["Cannot Process that"],
                isGraph: true,
              },
            ]);
          }
          else if (question.toLowerCase().trim().includes("clean, deduplicate and check for net new records?")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: "Here is the summary after cleaning and deduplicating:\n1. File has 5 columns\n2. company_name column has 10% missing values\n3. email column has 20% missing values\n4. website column has 15% missing values\n5. contact_name has 5% missing values\n6. company_country  has 2% missing values\n7. There are no duplicates left.\n8. We found out there are 20 new contacts based on emails and 10 new accounts based on URL.".split('\n'),
                isGraph: false,
                suggestions: ["Send net new records to HubSpot lead routing flow?", "Update existing records in CRM with latest information from file?", "Ask questions about the file?"],
                isSuggestions: true
              },
            ]);
          }else if (question.toLowerCase().trim().includes("ask questions about the file?")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: ["Please Proceed with your question"],
                isGraph: false,
                suggestions: ["Exit QnA"],
                isSuggestions: true
              },
            ]);
          }else if (question.toLowerCase().trim().includes("how many companies are in us")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: ["File has 24 companies belonging to US."],
                isGraph: false,
                suggestions: ["Exit QnA"],
                isSuggestions: true
              },
            ]);
          }else if (question.toLowerCase().trim().includes("generic emails")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: ["There are 25 generic emails"],
                isGraph: false,
                suggestions: ["Exit QnA"],
                isSuggestions: true
              },
            ]);
          }else if (question.toLowerCase().trim().includes("exit qna")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: "I want to Exit QnA",
                answer: ["Exiting QnA..."],
                isGraph: false,
                suggestions: ["Send net new records to Salesforce lead routing flow?", "Update existing records in Salesforce with latest information from file?", "Ask questions about the file?"],
                isSuggestions: true
              },
            ]);
          }else if (question.toLowerCase().trim().includes("to salesforce lead routing")){
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: ["Sent record to Salesforce using existing connection name sallesforce_GTMC_sdmin."],
                isGraph: false,
                suggestions: ["Ask questions about the file?"],
                isSuggestions: true
              },
            ]);
          }
          else{
          // if (response?.status === 200) {
          //   // console.log(response?.data?.response);
  
          //     setChatArray((prevChatArray) => [
          //       ...prevChatArray,
          //       {
          //         question: question,
          //         answer: response?.data?.responseData?.split("\n") || [
          //           "Cannot Process that",
          //         ],
          //         sources: response?.data?.sources,
          //       },
          //     ]);
          // } else {
            setChatArray((prevChatArray) => [
              ...prevChatArray,
              {
                question: question,
                answer: ["Cannot Process That"],
                suggestions: []
              },
            ]);
          // }
        }
      }
        setQuestion("");
        setInputFilled(false);
        // setLoading(false);
      // }
      // else {
      //   if(question.toLowerCase().trim() == "download"){
      //     setLoading(true)
      //     setTimeout(() => {
      //       setLoading(false)
      //       const sampleCSVPath = '/cleanversion_crmdataset022024.csv';
      //       // Create a temporary anchor element
      //       const anchor = document.createElement('a');
      //       anchor.href = sampleCSVPath;
      //       anchor.download = 'cleanversion_crmdataset022024.csv';
      //       // Append the anchor element to the document and trigger the download
      //       document.body.appendChild(anchor);
      //       anchor.click();
      //       document.body.removeChild(anchor);
      //       setChatArray((prevChatArray) => [
      //         ...prevChatArray,
      //         {
      //           question: "Download File",
      //           answer: ["Download succesfull"] || [
      //             "Cannot Process that",
      //           ],
      //         },
      //       ]);
      //     }, 3000)
      //     setQuestion("")

      //   } else if (question.toLowerCase().trim().includes("share with")) {
      //     setLoading(true)
      //     let destination = question.split(" ")
      //     setTimeout(() => {
      //       setLoading(false)

      //       setLoading(false)
      //       setChatArray((prevChatArray) => [
      //         ...prevChatArray,
      //         {
      //           question: question,
      //           answer: ["File record has been ingested to " + destination[2]] || [
      //             "Cannot Process that",
      //           ],
      //         },
      //       ]);
      //     }, 3000)     
      //     setQuestion("")

      //   } else if ((question.toLowerCase().trim().includes("many records") || question.toLowerCase().trim().includes("many total records")) && !question.toLowerCase().trim().includes("without email")) {
      //     setLoading(true)
      //     setTimeout(() => {
      //       setLoading(false)
      //       setChatArray((prevChatArray) => [
      //         ...prevChatArray,
      //         {
      //           question: question,
      //           answer: ["There are in total 51 records in the file"] || [
      //             "Cannot Process that",
      //           ],
      //         },
      //       ]);
      //     }, 3000)     
      //     setQuestion("")

      //   }  else if (question.toLowerCase().trim().includes("duplicate records")) {
      //     setLoading(true)
      //     setTimeout(() => {
      //       setLoading(false)
      //       setChatArray((prevChatArray) => [
      //         ...prevChatArray,
      //         {
      //           question: question,
      //           answer: ["I've found 10 duplicate records in the file"] || [
      //             "Cannot Process that",
      //           ],
      //         },
      //       ]);
      //     }, 3000)      
      //     setQuestion("")

      //   } else if (question.toLowerCase().trim().includes("without email")) {
      //     setLoading(true)
      //     setTimeout(() => {
      //       setLoading(false)
      //       setChatArray((prevChatArray) => [
      //         ...prevChatArray,
      //         {
      //           question: question,
      //           answer: ["There are 8 contacts without Email"] || [
      //             "Cannot Process that",
      //           ],
      //         },
      //       ]);
      //     }, 3000)     
      //     setQuestion("")
      //   }
      // }
    }

  };

  return (
    <div className="h-[80%]">
      <div ref={containerRef} className="pl-10 w-full h-[90%]" style={{overflowY: "scroll", overflowX: "hidden"}}>
        <HomeResChat attachedFile={attachedFile} setQuestion={setQuestion} setInputFilled={setInputFilled} handleSend={handleSend} chatArray={chatArray} setLoading={setLoading} source={"demo_v2"} setAttachedFile={setAttachedFile} setChatArray={setChatArray} />
      </div>
      {loading ? <LoaderWithText />
      : (
        <div className="w-[50%] absolute bottom-[5%] left-[25%]">
          <div className="border border-[#4B9DA1] bg-[#4B9DA1] rounded-full p-1">
            <div
              className={`border border-[#609DA1] shadow-xl rounded-full px-2 py-1 flex justify-between items-center bg-white`}
            >
              <div className="flex gap-5 justify-center items-center w-[80%]">
                <PlusCircleOutlined style={{ fontSize: "20px", cursor: "not-allowed" }}/>
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  value={question}
                  rows={rows}
                  onChange={handleInputChange}
                  placeholder="Ask follow-up..."
                  className={`text-xl w-full laterTextArea blackText`}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSend();
                    }
                  }}
                />
              </div>
              <div className="flex justify-center items-center gap-5 mt-0.5">
                <div className="flex justify-center items-center gap-1">
                  <Switch className="bg-[#243858]" disabled/>
                  <div className="text-[1rem]">Copilot</div>
                </div>
                <div
                  className={`flex justify-center items-center gap-2 border-2 rounded-2xl p-2 ${
                    inputFilled
                      ? "bg-[#609DA1] cursor-pointer"
                      : "cursor-not-allowed"
                  }`}
                  onClick={() => handleSend()}
                >
                  <ArrowUpOutlined
                    style={{ color: inputFilled ? "white" : "black" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeLater_v2;
