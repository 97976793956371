import React from "react";
import { Input } from "antd";
import { PlusCircleFilled } from '@ant-design/icons'

const ActiveComp2 = ({ setActiveComp }) => {
  return (
    <div className="flex flex-col items-start justify-center h-full px-32 gap-2">
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="source" className="ml-0.5 text-[1rem]">
          Source & Signals
        </label>
        <Input id="source" defaultValue="Source = Salesforce AND Signals = oGraph" />
        <div className="flex items-center gap-2">
            <PlusCircleFilled className="text-[#609DA1] cursor-not-allowed text-xl"/>
            <div>Add Additional Context</div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="trigger" className="ml-0.5 text-[1rem]">
          Triggers
        </label>
        <Input id="trigger" defaultValue="Champion Left = True OR Regulatory Indicators = Negative OR M&A News = Negative" />
        <div className="flex items-center gap-2">
            <PlusCircleFilled className="text-[#609DA1] cursor-not-allowed text-xl"/>
            <div>Add Additional Context</div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="action" className="ml-0.5 text-[1rem]">
          Actions
        </label>
        <Input
          id="action"
          defaultValue="Tag Accounts where Champion Left = True OR Regulatory Indicators = Negative OR M&A News = Negative"
        />
        <Input
          id="action"
          defaultValue="Send slack message account data AND next best action TO Account Owner AND Tag Account IN Salesforce"
        />
        <div className="flex items-center gap-2">
            <PlusCircleFilled className="text-[#609DA1] cursor-not-allowed text-xl"/>
            <div>Add Additional Context</div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-2">
        <label htmlFor="destination" className="ml-0.5 text-[1rem]">
          Destinations
        </label>
        <Input id="destination" defaultValue="Slack AND Salesforce" />
        <div className="flex items-center gap-2">
            <PlusCircleFilled className="text-[#609DA1] cursor-not-allowed text-xl"/>
            <div>Add Additional Context</div>
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-2">
        <div className="cursor-not-allowed px-[15px] leading-[40px] h-[40px] font-semibold font-[14px] border border-[#609DA1] text-[#609DA1] rounded-md">
          Save
        </div>
        <div
          className="cursor-pointer px-[15px] leading-[40px] h-[40px] font-semibold font-[14px] border border-[#609DA1] text-[#609DA1] rounded-md hover:bg-[#609DA1] hover:text-white"
          onClick={() => setActiveComp(3)}
        >
          Test
        </div>
        <div className="cursor-not-allowed px-[15px] leading-[40px] h-[40px] font-semibold font-[14px] border border-[#609DA1] text-[#609DA1] rounded-md">
          Launch
        </div>
      </div>
    </div>
  );
};

export default ActiveComp2;
