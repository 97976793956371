import React, { useState, useEffect } from "react";
import loadingGif from '../../assets/images/loaderNew.gif';

const LoaderWithText = () => {
  const [loaderText, setLoaderText] = useState(
    "Gathering and cleaning relevant data"
  );
  const loaderTextOpt = [
    "Analyzing data and visualizing outputs",
    "Creating insights and recommendations",
    "Sharing analysis results",
    "Finalizing results",
  ];

  useEffect(() => {
    let pos = 0;
    const loaderInterval = setInterval(() => {
      setLoaderText(loaderTextOpt[pos]);
      pos++;
      if (pos >= loaderTextOpt.length) {
        clearInterval(loaderInterval); // Stop the interval after reaching "Finalizing results"
      }
    }, 3000);

    return () => clearInterval(loaderInterval);
  }, []); // Empty dependency array to run only on mount

  return (
    <div className="flex justify-center" style={{ alignItems: "center" }}>
      <img src={loadingGif} alt="loadingGif" className="h-[60px]" />
      <div>{loaderText}</div>
    </div>
  );
}

export default LoaderWithText;
