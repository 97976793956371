import { Client } from "./Client";

export const saveUTM = async (formdata) => {
  try {
    return await Client.post("/utm/saveUTM", formdata);
  } catch (e) {
    return e;
  }
};

export const getDistinctSites = async (formdata) => {
  try {
    return await Client.post("/utm/getSites", formdata);
  } catch (e) {
    return e;
  }
}

export const getCombinations = async (formdata) => {
  try {
    return await Client.post("/utm/getCombinations", formdata);
  } catch (e) {
    return e;
  }
}

export const getAllTraffic = async (formdata) => {
  try {
    return await Client.post("/utm/getAllTraffic", formdata);
  } catch (e) {
    return e;
  }
}

export const getTotal = async (formdata) => {
  try {
    return await Client.post("/utm/getTotal", formdata);
  } catch (e) {
    return e;
  }
}