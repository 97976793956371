import React, { useState, useEffect } from "react";
import { Select, Table } from "antd";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  getDistinctSites,
  getCombinations,
  getAllTraffic,
  getTotal,
} from "../../services/utmServices.js";
import gtmCopilotName from "../../assets/images/gtmCopilotname.svg";

ChartJS.register(ArcElement, Tooltip, Legend);


const UTMAdmin = () => {
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [combination, setCombination] = useState([]);
  const [combinationOptions, setCombinationOptions] = useState([]);
  const [selectedCombination, setSelectedCombination] = useState(null);

  const [trafficData, setTrafficData] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [dataForChart, setDataForChart] = useState(null);
  const [chartData, setChartData] = useState(null);

  const formatDate = (dateString) => {
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  const getSelectOptions = async () => {
    const response = await getDistinctSites({});
    console.log(response?.data);
    if (response?.status && response?.status === 200) {
      setSelectOptions(
        response?.data?.map(({ baseurl }) => ({
          label: baseurl,
          value: baseurl,
        }))
      );
    }
    const combinations = await getCombinations({});
    console.log(combinations?.data);
    const actualCombinationObject = combinations?.data?.reduce(
      (acc, { baseurl, combinations }) => {
        const modifiedCombination = combinations?.map(
          ({ source, medium, campaign }) =>
            `(${source}) | (${medium}) | (${campaign})`
        );
        acc[baseurl] = modifiedCombination;
        return acc;
      },
      {}
    );
    console.log(actualCombinationObject, "combinationsssssssss");
    setCombination(actualCombinationObject);

    const traffic = await getAllTraffic({});
    if (traffic?.data) {
      setTrafficData(traffic.data);
    }
  };
  useEffect(() => {
    getSelectOptions();
  }, []);

  const getTotalVal = async () => {
    const res = await getTotal({ siteName: selectedOption });
    console.log(res?.data);
    if (res?.data && res?.data?.length > 0) {
      setDataForChart(
        res?.data?.map((item) => ({
          xValue: item?.site_href,
          yValue: item?.count,
        }))
      );
    }
  };

  useEffect(() => {
    if (selectedOption) {
      const val = combination[selectedOption];
      console.log(val, "valllllllllllll");
      setCombinationOptions(val?.map((item) => ({ label: item, value: item })));
      setSelectedCombination(null);
      setDataSource(null);
      setDataForChart(null);
      setChartData(null);

      getTotalVal();
    }
  }, [selectedOption, combination]);

  useEffect(() => {
    if (selectedCombination && selectedOption) {
      console.log(selectedCombination);
      console.log(selectedOption);
      console.log(trafficData, "Actual Trafficcccccccc");

      const data = selectedCombination
        .split("|")
        ?.map((item) => item?.trim()?.slice(1, -1));
      console.log(data, "combinationSplitted");

      const finalData = trafficData
        ?.filter((item) => {
          const comb = `?utm_source=${data[0]}|utm_medium=${data[1]}|utm_campaign=${data[2]}`;
          return (
            item?.site_name === selectedOption &&
            item?.site_href?.includes(comb)
          );
        })
        ?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      console.log(finalData, "finaldatatatatatatat");
      if (finalData && finalData?.length > 0) {
        setDataSource(
          finalData?.map((item, index) => ({
            key: index,
            name: item?.user_name?.replaceAll("|", " "),
            email: item?.user_email,
            country: item?.country_name,
            region: item?.region_name,
            clientip: item?.ip,
            timestamp: formatDate(item?.timestamp),
          }))
        );
      }
    }
  }, [selectedCombination]);

  useEffect(() => {
    if (dataForChart) {
      console.log(dataForChart);
      const backgroundColors = [
        "#E07E65",
        "#F39F8E",
        "#8E4E2A",
        "#653712",
        "#F9B8A6",
      ];
      setChartData({
        labels: dataForChart.map((item) =>
          item?.xValue
            ?.split("?")[1]
            ?.split("|")
            ?.map((i) => i?.split("=")[1])
            ?.join(" | ")
        ),
        datasets: [
          {
            label: "Traffic",
            data: dataForChart.map((item) => item.yValue),
            backgroundColor: backgroundColors,
          },
        ],
      });
    }
  }, [dataForChart]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      //   tooltip: {
      //     enabled: false,
      //   },
    },
  };

  const columns = [
    {
      title: "User Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "User Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Country",
      key: "country",
      dataIndex: "country",
    },
    {
      title: "Region",
      key: "region",
      dataIndex: "region",
    },
    {
      title: "Client IP",
      key: "clientip",
      dataIndex: "clientip",
    },
    {
      title: "Timestamp",
      key: "timestamp",
      dataIndex: "timestamp",
    },
  ];
  return (
    <div className="flex flex-col h-[90vh] gap-8 relative">
      <div className="absolute top-5 left-8">
        <img src={gtmCopilotName} alt="gtm-logo" width={150} />
      </div>
      <div className="absolute right-10 top-2 flex flex-col gap-10 justify-center items-center w-[30%]">
        <Select
          className="w-full"
          options={selectOptions}
          placeholder="Select Base URL"
          onChange={(e) => setSelectedOption(e)}
          value={selectedOption}
        />
        <Select
          className="w-full"
          value={selectedCombination}
          onChange={(e) => setSelectedCombination(e)}
          options={combinationOptions}
          placeholder="(Source) | (Medium) | (Campaign) Combination"
        />
      </div>
      <div className="h-1/2 w-full flex justify-center items-center mt-4">
        {chartData && <Doughnut data={chartData} options={options} />}
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        bordered
        pagination={false}
        className="w-full px-6"
      />
    </div>
  );
};

export default UTMAdmin;
